import { type DataKey } from '@via/schema'
import { type Except } from 'type-fest'

import {
  ScenarioMonetaryTable,
  type ScenarioMonetaryTableProps,
} from '../ScenarioMonetaryTable/ScenarioMonetaryTable.tsx'

import { useScenarioUdpBlueprint, type UseScenarioUdpBlueprintOptions } from './useScenarioUdpBlueprint.ts'

export interface ScenarioMonetaryUdpTableProps<TUdpKey extends string = DataKey, TableStates extends string = string>
  extends Except<ScenarioMonetaryTableProps<TUdpKey, TableStates, never, never>, 'blueprint' | 'onWeightChange'>,
    UseScenarioUdpBlueprintOptions<TUdpKey, TableStates> {}

export const ScenarioMonetaryUdpTable = <TUdpKey extends string = DataKey, TableStates extends string = string>({
  blueprint: udpBlueprint,
  readonly,
  active,
  ...props
}: ScenarioMonetaryUdpTableProps<TUdpKey, TableStates>) => {
  const blueprint = useScenarioUdpBlueprint({ blueprint: udpBlueprint, readonly, active })

  return (
    <ScenarioMonetaryTable<TUdpKey, TableStates, never, never> blueprint={blueprint} {...props}></ScenarioMonetaryTable>
  )
}
