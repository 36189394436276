import { type FC, useContext } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@tanstack/react-router'

import { Icons } from '../../atoms'
import { Button } from '../../atoms/Button/Button'
import { Tooltip } from '../../atoms/Tooltip/Tooltip'
import { BudgetReferenceUpdateDialogContext } from '../../context'
import { ScenarioLayoutFooterHeightContext } from '../../context/layout/ScenarioLayoutFooterHeightContext.ts'
import { cn } from '../../lib/utils.ts'
import { type BudgetData, type ScenarioData } from '../../types'
import {
  ScenarioNavigationMenu,
  type ScenarioNavigationMenuProps,
} from '../ScenarioNavigationMenu/ScenarioNavigationMenu'

export interface ScenarioNavigationProps {
  readonly scenario?: ScenarioData
  readonly budget: BudgetData
  readonly currentScenarioNavigationId: 'create' | 'update' | ScenarioNavigationMenuProps['active']
  readonly syncRequired?: boolean
  readonly offline?: boolean
  readonly withConfiguration?: boolean
  readonly className?: string
  readonly navigationMenuCollapsedState?: ScenarioNavigationMenuProps['collapsed']
  readonly onNavigationMenuCollapse?: ScenarioNavigationMenuProps['onCollapse']
  readonly onRefreshScenarioReference?: () => Promise<void> | void
  readonly onUpdateScenarioReferences?: () => Promise<void> | void
}

export const ScenarioNavigation: FC<ScenarioNavigationProps> = ({
  currentScenarioNavigationId,
  offline,
  budget,
  scenario,
  syncRequired,
  withConfiguration = true,
  className,
  navigationMenuCollapsedState,
  onRefreshScenarioReference,
  onUpdateScenarioReferences,
  onNavigationMenuCollapse,
}) => {
  const intl = useIntl()
  const { open } = useContext(BudgetReferenceUpdateDialogContext)
  const footerHeight = useContext(ScenarioLayoutFooterHeightContext)

  const onViagritelUpdateClick = (): Promise<void> | void => {
    if (syncRequired) {
      open({
        onConfirm: async () => {
          await onUpdateScenarioReferences?.()
        },
      })
      return
    }

    // eslint-disable-next-line consistent-return
    return onRefreshScenarioReference?.()
  }

  return (
    <div
      className={cn(
        'fixed z-40 flex w-32 flex-col overflow-y-auto overscroll-y-none bg-white pl-3 pr-5 pt-4',
        className
      )}
      style={{ top: 84, bottom: footerHeight }}>
      {currentScenarioNavigationId === 'create' || currentScenarioNavigationId === 'update' ? (
        withConfiguration ? (
          <>
            <Link
              className="flex select-none rounded border border-sage-400 bg-sage-50 px-2 py-1.5"
              to={
                currentScenarioNavigationId === 'create'
                  ? '/budgets/$budgetId/scenarios/create'
                  : '/budgets/$budgetId/scenarios/$scenarioId/configuration'
              }
              disabled={currentScenarioNavigationId === 'create'}
              params={
                currentScenarioNavigationId === 'create'
                  ? { budgetId: budget._id }
                  : { budgetId: budget._id, scenarioId: scenario!._id }
              }>
              <p className="text-left text-xs font-medium uppercase leading-none tracking-[-0.42px] text-gray-800">
                {intl.formatMessage({ id: 'scenarioNav.link.configuration' })}
              </p>
            </Link>
            <div className="pt-8">
              {scenario?.referenceType === 'viagritel' && (
                <Tooltip label={intl.formatMessage({ id: 'scenario.navigationItems.viagritel.tooltip' })}>
                  <Button
                    variant="viagritel"
                    className="w-full"
                    onClick={onViagritelUpdateClick}
                    disabled={offline || budget.readonly || !onViagritelUpdateClick}>
                    <Icons.ArrowClockWise /> {intl.formatMessage({ id: 'scenario.navigationItems.viagritel' })}
                  </Button>
                </Tooltip>
              )}
              {scenario?.referenceSyncedDate && (
                <>
                  <p className="pt-1 text-xxs font-thin leading-tight tracking-[-0.4px] text-black">
                    {intl.formatMessage({ id: 'scenario.navigationItems.updatedAt' })}
                  </p>
                  <p className="pt-1 text-xxs font-thin leading-tight tracking-[-0.4px] text-black">
                    {intl.formatDate(scenario.referenceSyncedDate, {
                      year: 'numeric',
                      month: '2-digit',
                      day: 'numeric',
                    })}
                  </p>
                  <p className="pt-1 text-xxs font-thin leading-tight tracking-[-0.4px] text-black">
                    {intl.formatTime(scenario.referenceSyncedDate, { hour: '2-digit', minute: '2-digit' })}
                  </p>
                </>
              )}
            </div>
          </>
        ) : null
      ) : (
        <ScenarioNavigationMenu
          blueprintVersion={scenario?.blueprintVersion}
          enabledProductions={scenario?.enabledProductions}
          active={currentScenarioNavigationId}
          withConfiguration={withConfiguration}
          collapsed={navigationMenuCollapsedState}
          onCollapse={onNavigationMenuCollapse}
        />
      )}
    </div>
  )
}
