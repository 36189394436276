import { type Except } from 'type-fest'

import { type BlueprintUdpTable } from '../blueprint.ts'
import { CropsWeights } from '../weights/cropsWeights.ts'

import { cropsResultsSheet2024Blueprint } from './cropsResultsSheet2024Blueprint.ts'

export const cropsResultsUdp2024Blueprint = {
  columns: cropsResultsSheet2024Blueprint.columns,
  defaultState: cropsResultsSheet2024Blueprint.defaultState,
  expendedState: cropsResultsSheet2024Blueprint.expendedState,
  udp: CropsWeights,
} as const satisfies Except<BlueprintUdpTable<typeof CropsWeights, 'selected-reference' | 'references'>, 'version'>
