import { type FC } from 'react'

import { CropsResultsView } from '@via/components'

import { useCropsResultsPage } from './useCropsResultsPage.ts'

export const CropsResultsPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useCropsResultsPage()
  return <CropsResultsView key={props.scenario._id} snapshot={snapshot} {...props} />
}
