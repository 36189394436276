import { type FC } from 'react'

import { MilkResultsView } from '@via/components'

import { useMilkResultsPage } from './useMilkResultsPage.ts'

export const MilkResultsPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useMilkResultsPage()
  return <MilkResultsView key={props.scenario._id} snapshot={snapshot} {...props} />
}
