import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, dividerLine } from '../utils'

export const maintenanceSheet2024Blueprint = {
  sections: [
    {
      id: 'maintenance.machinery',
      titleKey: 'table.maintenance.machinery',
      children: [
        {
          ...dividerLine({ border: 'top' }, 'maintenance.machinery'),
          children: [
            dataLine('maintenance.machinery.tools', { displayAsProjection: true }),
            {
              ...dataLine('maintenance.machinery.equipment.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.machinery.equipment.provender', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.composting', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.incineration', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.manure', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.tractor', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.things', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.thresher', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.dryer', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.truck', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.vehicle', { displayAsProjection: true }),
                dataLine('maintenance.machinery.equipment.other', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('maintenance.machinery.fuel.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.machinery.fuel.tractor', { displayAsProjection: true }),
                dataLine('maintenance.machinery.fuel.thresher', { displayAsProjection: true }),
                dataLine('maintenance.machinery.fuel.truck', { displayAsProjection: true }),
                dataLine('maintenance.machinery.fuel.other', { displayAsProjection: true }),
                dataLine('maintenance.machinery.fuel.propane', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('maintenance.machinery.rental.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.machinery.rental.tractor', { displayAsProjection: true }),
                dataLine('maintenance.machinery.rental.other', { displayAsProjection: true }),
                dataLine('maintenance.machinery.rental.shared-machinery', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('maintenance.machinery.total', { variant: 'total', closingSection: true }),
      ],
    },
    {
      id: 'maintenance.contract-work',
      titleKey: 'table.maintenance.contract-work',
      children: [
        {
          ...dividerLine({ border: 'top' }, 'maintenance.contract-work'),
          children: [
            dataLine('maintenance.contract-work.soil-preparation', { displayAsProjection: true }),
            dataLine('maintenance.contract-work.manure-spreading', { displayAsProjection: true }),
            {
              ...dataLine('maintenance.contract-work.miscellaneous.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.contract-work.miscellaneous.snow-removal', { displayAsProjection: true }),
                dataLine('maintenance.contract-work.miscellaneous.crop-transport', { displayAsProjection: true }),
                dataLine('maintenance.contract-work.miscellaneous.machinery-transport', { displayAsProjection: true }),
                dataLine('maintenance.contract-work.miscellaneous.storage', { displayAsProjection: true }),
                dataLine('maintenance.contract-work.miscellaneous.other', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('maintenance.contract-work.total', { variant: 'total', closingSection: true }),
      ],
    },
    {
      id: 'maintenance.asset',
      titleKey: 'table.maintenance.asset',
      children: [
        {
          ...dividerLine({ border: 'top' }, 'maintenance.asset'),
          children: [
            {
              ...dataLine('maintenance.asset.building.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.asset.building.provender', { displayAsProjection: true }),
                dataLine('maintenance.asset.building.composting', { displayAsProjection: true }),
                dataLine('maintenance.asset.building.incineration', { displayAsProjection: true }),
                dataLine('maintenance.asset.building.other', { displayAsProjection: true }),
                dataLine('maintenance.asset.building.heating', { displayAsProjection: true }),
                dataLine('maintenance.asset.building.storage', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('maintenance.asset.land.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.asset.land.landed-property', { displayAsProjection: true }),
                dataLine('maintenance.asset.land.yard-fence', { displayAsProjection: true }),
                dataLine('maintenance.asset.land.mechanized-work', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('maintenance.asset.rental.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('maintenance.asset.rental.building', { displayAsProjection: true }),
                dataLine('maintenance.asset.rental.land', { displayAsProjection: true }),
              ],
            },
            dataLine('maintenance.asset.other', { displayAsProjection: true }),
          ],
        },
        dataLine('maintenance.asset.total', { variant: 'total', closingSection: true }),
      ],
    },
  ],
  summary: [
    dataLine('maintenance.machinery.total', { variant: 'finalSubTotal' }),
    dataLine('maintenance.contract-work.total', {
      variant: 'finalSubTotal',
      symbol: 'add',
    }),
    dataLine('maintenance.asset.total', {
      variant: 'finalSubTotal',
      symbol: 'add',
    }),
    dataLine('maintenance.total', { variant: 'finalTotal', symbol: 'total' }),
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true },
    {
      id: 'reference',
      type: 'reference',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'references',
      type: 'all-references',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
      displayOnTableState: ['selected-reference', 'references'],
    },
    {
      id: 'projections',
      type: 'result-projections',
      withDividerRight: true,
      className: 'w-24',
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'references',
} as const satisfies Except<BlueprintTable<'selected-reference' | 'references'>, 'version'>
