import { convertRowData, type OverridableCellData, type RowData } from '@via/compute'
import { isEmpty } from 'lodash-es'

import { type OverridableMonetaryCellData } from '../MonetaryRowData.ts'

import { overridableMonetaryCellData } from './cell'

export const overridableMonetaryRowData = ({ weighted, ...rowData }: RowData<'overridable'>, weight?: string) =>
  convertRowData<OverridableCellData, OverridableMonetaryCellData, Omit<RowData<'overridable'>, 'weighted'>>(
    {
      ...rowData,
      ...(weight ? weighted?.[weight] : {}),
    },
    (cellData) => ({
      ...overridableMonetaryCellData(cellData),
      status: !isEmpty(cellData.override) ? ('overridden' as const) : null,
    })
  )
