import { type ScenarioResultsViewProps } from '@via/components'

import { useBudgetCallback } from '../../actions/useBudgetCallback.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useScenarioLayoutPage } from '../scenario/useScenarioLayoutPage.ts'

export const useScenarioResultsPage = (): ScenarioResultsViewProps => {
  const props = useScenarioLayoutPage('results')
  const { updateSelectedReferenceYear } = useCurrentScenarioHandler()
  const onReferenceChange = useBudgetCallback('onReferenceChange', updateSelectedReferenceYear)

  return {
    ...props,
    onReferenceChange,
  }
}
