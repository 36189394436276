import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import { Label } from '../../atoms/Label/Label'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import {
  ScenarioMonetaryLayout,
  type ScenarioMonetaryLayoutProps,
} from '../../organisms/ScenarioMonetaryLayout/ScenarioMonetaryLayout.tsx'

import { useScenarioResultsBlueprint } from './useScenarioResultsBlueprint.ts'

export type ScenarioResultsViewProps = Except<
  ScenarioMonetaryLayoutProps,
  'currentScenarioNavigationId' | 'collapsedReference' | 'blueprint'
>

export const ScenarioResultsView: FC<ScenarioResultsViewProps> = ({ scenario, currentBlueprintState, ...props }) => {
  const intl = useIntl()
  const blueprint = useScenarioResultsBlueprint(scenario)

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMonetaryLayout
        currentScenarioNavigationId="results"
        collapsedReference="selected"
        scenario={scenario}
        blueprint={blueprint}
        currentBlueprintState={!scenario.selectedReferenceYear ? 'select' : currentBlueprintState}
        {...props}>
        <div className="flex">
          <div className="sticky left-36 pl-8 pt-6">
            <Label className="text-2xl font-bold uppercase text-black">
              {intl.formatMessage({ id: 'financialResults.name' })}
            </Label>
          </div>
        </div>
      </ScenarioMonetaryLayout>
    </MonetaryTableEditingStateProvider>
  )
}
