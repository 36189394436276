import { type RowData } from '@via/compute'
import {
  type AssetComputationType,
  type BlueprintVersion,
  type InventoryComputationType,
  InventoryComputationTypeZod,
  type InventoryTotalComputationType,
  InventoryTotalComputationTypeZod,
  type LoanComputationType,
  type MilkIncomeComputationType,
  MilkIncomeComputationTypeZod,
  type MilkQuotaComputationType,
  MilkQuotaComputationTypeZod,
  type MovementComputationType,
} from '@via/schema'

import { type MonetaryRowData } from '../MonetaryRowData'

import { weightedGrbfMonetaryRowData } from './grbfMonetaryRowData'
import { investmentMonetaryRowData } from './investmentMonetaryRowData'
import { loanMonetaryRowData } from './loanMonetaryRowData'
import { loanTotalMonetaryRowData } from './loanTotalMonetaryRowData'
import { movementMonetaryRowData } from './movementMonetaryRowData.ts'
import { overridableMonetaryRowData } from './overridableMonetaryRowData.ts'
import { monetaryFromRecordRowData, monetaryFromWeightedCellRowData } from './utils.ts'

const assetComputationType = (computation: string | undefined): AssetComputationType => {
  switch (computation) {
    case 'value':
    case 'amortization':
      return computation
    default:
      return 'value'
  }
}

const loanComputationType = (computation: string | undefined): LoanComputationType => {
  switch (computation) {
    case 'annuity':
    case 'interest':
    case 'capital':
    case 'projectedCapital':
      return computation
    case 'endingBalance':
      return 'remainingDueAmount'
    case 'disbursements':
      return 'newCapitalBorrowed'
    case 'repayment':
      return 'earlyRepayment'
    case 'exigible':
      return 'exigibleCapital'
    default:
      return 'annuity'
  }
}

const movementComputationType = (computation: string | undefined): MovementComputationType | undefined => {
  switch (computation) {
    case 'startingBalance':
    case 'balance':
      return computation
    default:
      return undefined
  }
}

const milkQuotaComputationType = (tab: string | undefined): MilkQuotaComputationType => {
  const result = MilkQuotaComputationTypeZod.safeParse(tab)
  switch (true) {
    case result.success:
      return result.data
    default:
      return 'startingBalance'
  }
}

const milkIncomeComputationType = (tab: string | undefined): MilkIncomeComputationType => {
  const result = MilkIncomeComputationTypeZod.safeParse(tab)
  switch (true) {
    case result.success:
      return result.data
    default:
      return 'total'
  }
}

const inventoryComputationType = (tab: string | undefined): InventoryComputationType => {
  const result = InventoryComputationTypeZod.safeParse(tab)
  switch (true) {
    case result.success:
      return result.data
    case tab === 'balance':
      return 'unitBalance'
    default:
      return 'unitMovement'
  }
}

const inventoryTotalComputationType = (tab: string | undefined): InventoryTotalComputationType => {
  const result = InventoryTotalComputationTypeZod.safeParse(tab)
  switch (true) {
    case result.success:
      return result.data
    default:
      return 'value'
  }
}

export interface MonetaryRowDataOptions<Weight extends string> {
  computation?: string
  weight?: Weight
}

export const monetaryRowData = <Weight extends string>(
  id: string,
  rowData: RowData,
  blueprint: BlueprintVersion,
  options?: MonetaryRowDataOptions<Weight>
): MonetaryRowData => {
  const { computationType } = rowData
  switch (computationType) {
    case 'total':
    case 'composite':
    case 'calculation':
      return { id, blueprint, ...monetaryFromWeightedCellRowData(rowData, options?.weight), weight: options?.weight }
    case 'movement-overridable':
    case 'movement-total':
      return { id, blueprint, ...movementMonetaryRowData(rowData, movementComputationType(options?.computation)) }
    case 'grbf':
      return { id, blueprint, ...weightedGrbfMonetaryRowData(rowData, options?.weight), weight: options?.weight }
    case 'overridable':
      return { id, blueprint, ...overridableMonetaryRowData(rowData, options?.weight), weight: options?.weight }
    case 'finance-loan':
    case 'finance-loan-investment':
      return {
        id,
        blueprint,
        // eslint-disable-next-line unicorn/consistent-destructuring
        stateDataId: rowData.loanId,
        ...loanMonetaryRowData(rowData, loanComputationType(options?.computation)),
      }
    case 'finance-loan-total':
      return { id, blueprint, ...loanTotalMonetaryRowData(rowData, loanComputationType(options?.computation)) }
    case 'finance-asset':
    case 'finance-asset-inventory':
      return {
        id,
        blueprint,
        // eslint-disable-next-line unicorn/consistent-destructuring
        stateDataId: rowData.assetId,
        ...monetaryFromRecordRowData(rowData, assetComputationType(options?.computation)),
      }
    case 'finance-asset-total':
      return { id, blueprint, ...monetaryFromRecordRowData(rowData, assetComputationType(options?.computation)) }
    case 'finance-investment':
    case 'finance-investment-inventory':
      return {
        id,
        blueprint,
        // eslint-disable-next-line unicorn/consistent-destructuring
        stateDataId: rowData.investmentId,
        ...investmentMonetaryRowData(rowData, 'disbursement'),
      }
    case 'finance-investment-total':
      return { id, blueprint, ...monetaryFromRecordRowData(rowData, 'actualDisbursement') }
    case 'milk-quota':
      return { id, blueprint, ...monetaryFromRecordRowData(rowData, milkQuotaComputationType(options?.computation)) }
    case 'milk-income':
      return { id, blueprint, ...monetaryFromRecordRowData(rowData, milkIncomeComputationType(options?.computation)) }
    case 'inventory':
      return { id, blueprint, ...monetaryFromRecordRowData(rowData, inventoryComputationType(options?.computation)) }
    case 'inventory-total':
      return {
        id,
        blueprint,
        ...monetaryFromRecordRowData(rowData, inventoryTotalComputationType(options?.computation)),
      }
    default:
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      throw new Error(`Unknown rowData computation type ${computationType}`)
  }
}
