import { type FC, type PropsWithChildren, type RefObject, useContext, useMemo } from 'react'

import { sumBy } from 'lodash-es'

import { TableNavigationProvider } from '../../../context/table/TableNavigationProvider'

import { MonetaryTableCurrentBlueprintColumnsContext } from './MonetaryTableCurrentBlueprintColumnsContext.ts'
import { MonetaryTablePropsContext } from './MonetaryTablePropsContext.ts'

interface MonetaryTableDividerCellProps {
  readonly containerRef: RefObject<HTMLElement>
}

export const MonetaryTableDividerCell: FC<PropsWithChildren<MonetaryTableDividerCellProps>> = ({
  containerRef,
  children,
}) => {
  const { withNavigation, references, projections } = useContext(MonetaryTablePropsContext)
  const blueprintColumns = useContext(MonetaryTableCurrentBlueprintColumnsContext)

  const nbCellsPerRow = useMemo(
    () =>
      sumBy(blueprintColumns, (column) => {
        switch (column.type) {
          case 'divider':
          case 'title':
          case 'title-with-add':
          case 'title-with-add-investment':
            return 0
          case 'all-references':
            return references.length
          case 'historic':
            return 2
          case 'all-historic':
            return references.length * 2
          case 'result-projections':
          case 'projections':
            return projections.length
          case 'projections-with-proportions':
            return projections.length * 2
          default:
            return 1
        }
      }),
    [blueprintColumns, projections.length, references.length]
  )

  return (
    <TableNavigationProvider containerRef={containerRef} nbCellsPerRow={nbCellsPerRow} withNavigation={withNavigation}>
      {children}
    </TableNavigationProvider>
  )
}
