import { type PropsWithChildren, useMemo } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { type DataKey } from '@via/schema'

import { type MonetaryTableBlueprint, type MonetaryTableColumn } from '../types.ts'

import { MonetaryTableBlueprintContext } from './MonetaryTableBlueprintContext.ts'
import { MonetaryTableCurrentBlueprintColumnsContext } from './MonetaryTableCurrentBlueprintColumnsContext.ts'
import { MonetaryTableCurrentBlueprintStateContext } from './MonetaryTableCurrentBlueprintStateContext.ts'
import { MonetaryTableCurrentBlueprintTabContext } from './MonetaryTableCurrentBlueprintTabContext.ts'
import { MonetaryTableCurrentBlueprintWeightContext } from './MonetaryTableCurrentBlueprintWeightContext.ts'
import { MonetaryTableStateSetterContext, type MonetaryTableStateSetterState } from './MonetaryTableStateSetterState.ts'

export interface MonetaryTableStateContextProviderProps<
  Key extends string = string,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
> {
  readonly blueprint: MonetaryTableBlueprint<Key, TableStates, TabValues, Weight>
  readonly currentBlueprintState?: TableStates
  readonly currentBlueprintTab?: TabValues
  readonly currentWeight?: Weight

  readonly onBlueprintStateChange?: (tab: TableStates) => void
  readonly onTabChange?: (tab: TabValues) => void
  readonly onWeightChange?: (weight?: Weight) => void
}

export const MonetaryTableStateProvider = <
  Key extends string = DataKey,
  TableStates extends string = never,
  TabValues extends string = never,
  Weight extends string = never,
>({
  blueprint,
  currentBlueprintState,
  currentBlueprintTab,
  currentWeight,
  onBlueprintStateChange,
  onTabChange,
  onWeightChange,
  children,
}: PropsWithChildren<MonetaryTableStateContextProviderProps<Key, TableStates, TabValues, Weight>>) => {
  const [blueprintState, setBlueprintState] = useControllableState<TableStates>({
    prop: currentBlueprintState,
    defaultProp: blueprint.defaultState,
    onChange: onBlueprintStateChange,
  })
  const [tabState, setBlueprintTab] = useControllableState<TabValues>({
    prop: currentBlueprintTab,
    defaultProp: blueprint.defaultTab,
    onChange: onTabChange,
  })

  const setters = useMemo(
    () => ({
      onBlueprintStateChange: (value: TableStates) => {
        setBlueprintState(value)
      },
      onExpandReference: (expanded: boolean) => {
        if (blueprint.expendedState) {
          setBlueprintState(expanded ? blueprint.expendedState : blueprint.defaultState)
        }
      },
      onTabChange: (value: TabValues) => {
        setBlueprintTab(value)
      },
      onWeightChange,
    }),
    [setBlueprintState, setBlueprintTab, onWeightChange, blueprint.expendedState, blueprint.defaultState]
  )

  const blueprintColumns = useMemo(
    () =>
      blueprint.columns
        .filter(
          (col) =>
            col.displayOnTableState === undefined ||
            blueprintState === undefined ||
            col.displayOnTableState.includes(blueprintState)
        )
        .filter((col) => col.enabled !== false)
        .filter(
          (col) =>
            col.displayOnTableActiveTab === undefined || !tabState || col.displayOnTableActiveTab.includes(tabState)
        ),
    [blueprint.columns, blueprintState, tabState]
  )

  return (
    <MonetaryTableBlueprintContext.Provider value={blueprint as unknown as MonetaryTableBlueprint}>
      <MonetaryTableCurrentBlueprintStateContext.Provider value={blueprintState}>
        <MonetaryTableCurrentBlueprintTabContext.Provider value={tabState}>
          <MonetaryTableCurrentBlueprintWeightContext.Provider value={currentWeight}>
            <MonetaryTableCurrentBlueprintColumnsContext.Provider value={blueprintColumns as MonetaryTableColumn[]}>
              <MonetaryTableStateSetterContext.Provider value={setters as MonetaryTableStateSetterState}>
                {children}
              </MonetaryTableStateSetterContext.Provider>
            </MonetaryTableCurrentBlueprintColumnsContext.Provider>
          </MonetaryTableCurrentBlueprintWeightContext.Provider>
        </MonetaryTableCurrentBlueprintTabContext.Provider>
      </MonetaryTableCurrentBlueprintStateContext.Provider>
    </MonetaryTableBlueprintContext.Provider>
  )
}
