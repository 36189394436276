import { createFileRoute } from '@tanstack/react-router'

import { ScenarioFinancePage } from '../pages/scenarioFinance/ScenarioFinancePage'

const FianceComponent = () => <ScenarioFinancePage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
)({
  component: FianceComponent,
})
