import { type PropsWithChildren } from 'react'

import { type DataKey } from '@via/schema'

import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { ScenarioLayoutFooterHeightProvider } from '../../context/layout/ScenarioLayoutFooterHeightProvider.tsx'
import { ScenarioMainLayout, type ScenarioMainLayoutProps } from '../ScenarioMainLayout/ScenarioMainLayout.tsx'
import {
  ScenarioMonetaryTable,
  type ScenarioMonetaryTableProps,
} from '../ScenarioMonetaryTable/ScenarioMonetaryTable.tsx'

export interface ScenarioMonetaryLayoutProps<
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
> extends Pick<
      ScenarioMainLayoutProps,
      | 'loading'
      | 'offline'
      | 'syncRequired'
      | 'snapshot'
      | 'snapshotName'
      | 'currentScenarioNavigationId'
      | 'navigationMenuCollapsedState'
      | 'onNavigationMenuCollapse'
      | 'onUpdateBudget'
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onUpdateScenarioReferences'
      | 'onNavigationChange'
    >,
    ScenarioMonetaryTableProps<Key, TableStates, TabValues, Weight> {}

export const ScenarioMonetaryLayout = <
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
>({
  budget,
  scenario,
  offline,
  loading,
  syncRequired,
  snapshot,
  snapshotName,
  currentScenarioNavigationId,
  navigationMenuCollapsedState,
  onNavigationMenuCollapse,
  onUpdateBudget,
  onNavigationChange,
  onCloseBudget,
  onRequestOwnership,
  onRefreshScenarioReference,
  onUpdateScenarioReferences,
  children,
  ...tableProps
}: PropsWithChildren<ScenarioMonetaryLayoutProps<Key, TableStates, TabValues, Weight>>) => (
  <ScenarioLayoutFooterHeightProvider>
    <MonetaryTableEditingStateProvider>
      <ScenarioMainLayout
        currentScenarioNavigationId={currentScenarioNavigationId}
        budget={budget}
        scenario={scenario}
        syncRequired={syncRequired}
        offline={offline ?? false}
        loading={loading}
        snapshot={snapshot}
        snapshotName={snapshotName}
        navigationMenuCollapsedState={navigationMenuCollapsedState}
        onNavigationMenuCollapse={onNavigationMenuCollapse}
        onCloseBudget={onCloseBudget}
        onRequestOwnership={onRequestOwnership}
        onNavigationChange={onNavigationChange}
        onUpdateBudget={onUpdateBudget}
        onRefreshScenarioReference={onRefreshScenarioReference}
        onUpdateScenarioReferences={onUpdateScenarioReferences}>
        <div className="flex min-h-[calc(100vh-84px)] flex-1 flex-col pl-32">
          {children}
          <ScenarioMonetaryTable budget={budget} scenario={scenario} className="pt-5" {...tableProps} />
        </div>
      </ScenarioMainLayout>
    </MonetaryTableEditingStateProvider>
  </ScenarioLayoutFooterHeightProvider>
)
