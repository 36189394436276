import { type ScenarioState } from '@via/compute'

import { cropsResultsSheet2024Blueprint } from './2024/cropsResultsSheet2024Blueprint.ts'
import { type InferMonetaryTableBlueprint } from './inferMonetaryTableBlueprint.ts'

export type CropsResultsBlueprint = InferMonetaryTableBlueprint<typeof cropsResultsSheet2024Blueprint>

export const cropsResultsBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...cropsResultsSheet2024Blueprint }
  }
}
