import { useCallback, useMemo } from 'react'
import { useIntl } from 'react-intl'

import {
  type InvestmentOperations,
  type LoanOperations,
  type MilkOperations,
  type OnUpdateCellParams,
  useScenario,
} from '@via/components'
import { resolveAliasOrThrow, type YearKey } from '@via/schema'
import { mapValues, omit } from 'lodash-es'
import { type DistributedOmit } from 'type-fest'

import { useCreateBudgetReference } from '../../api/useCreateBudgetReference.ts'
import { useCurrentUserActionTimestamp } from '../../auth/useCurrentUserActionTimestamp.ts'
import { useAuthenticatedFirebaseAuth } from '../../auth/useFirebaseAuth.ts'
import { useComputeStateOperationCallback } from '../../worker/computeState/useComputeStateOperationCallback.ts'
import { type ScenarioUpdateData, type ScenarioUserEvent } from '../../worker/scenario/types.ts'
import { useScenarioHandler } from '../../worker/scenario/useScenarioHandler.ts'

export const useCurrentScenarioHandler = () => {
  const intl = useIntl()
  const { budgetId, _id: scenarioId, selectedReferenceYear, referenceType, blueprintVersion } = useScenario()
  const handler = useScenarioHandler()
  const modificationTimestamp = useCurrentUserActionTimestamp()
  const { user, userName, userRole } = useAuthenticatedFirebaseAuth()
  const { mutateAsync } = useCreateBudgetReference()

  const addEvent = useCallback(
    async (
      event: DistributedOmit<ScenarioUserEvent, 'userId' | 'timestamp' | 'userName' | 'userRole'>,
      onCompleted?: () => void
    ) => {
      await handler.addScenarioEvent(budgetId, scenarioId, {
        ...event,
        userId: user.uid,
        timestamp: new Date(),
        userName,
        userRole,
      })
      onCompleted?.()
    },
    [handler, budgetId, scenarioId, user.uid, userName, userRole]
  )

  const updateCell = useCallback(
    async (value: OnUpdateCellParams) => {
      const { type, dataKey } = value
      const key = resolveAliasOrThrow(dataKey, blueprintVersion)

      switch (type) {
        case 'result-projections':
          return addEvent({
            type: 'updateValue',
            key,
            ...omit(value, ['type', 'dataKey']),
          })

        case 'movement-overridable':
          return addEvent({
            type: 'updateValue',
            key,
            ...omit(value, ['type', 'dataKey']),
          })

        case 'growth':
          return addEvent({
            type: 'updateProjectionGrowth',
            key,
            ...omit(value, ['type', 'dataKey']),
          })
        case 'overridable-reference':
          return addEvent({
            type: 'updateReference',
            key,
            ...omit(value, ['type', 'dataKey']),
          })
        case 'reference':
          if (referenceType !== 'empty') {
            throw new Error('reference cannot be edited')
          }
          return addEvent({
            type: 'updateReference',
            year: 'EMPTY',
            key,
            ...omit(value, ['type', 'dataKey']),
          })
        case 'inventory': {
          const { year, inventory, overrides } = value
          return addEvent({
            type: 'updateInventory',
            key,
            year,
            inventory,
            overrides: mapValues(overrides, (v) => (v ? { value: v } : null)),
          })
        }

        default:
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          throw new Error(`Cannot update cell: ${value}`)
      }
    },
    [addEvent, blueprintVersion, referenceType]
  )

  const updateSelectedReferenceYear = useCallback(
    async (referenceYear: YearKey) => {
      if (referenceYear !== selectedReferenceYear) {
        await addEvent({
          type: 'updateSelectedReferenceYear',
          year: referenceYear,
        })
      }
    },
    [addEvent, selectedReferenceYear]
  )

  const updateReference = useComputeStateOperationCallback(
    async () => {
      const { id: referenceId, currentProductions } = await mutateAsync({ budgetId })
      return addEvent({ type: 'updateReferenceId', referenceId, currentProductions })
    },
    [mutateAsync, addEvent],
    intl.formatMessage({ id: 'scenario.wait.syncingReference' })
  )

  const updateLoan = useCallback<LoanOperations['onUpdateLoan']>(
    ({ type: _type, ...data }) =>
      addEvent({
        type: 'updateLoan',
        ...data,
      }),
    [addEvent]
  )

  const createLoan = useCallback<LoanOperations['onAddLoan']>(
    ({ type: _type, ...data }) =>
      addEvent({
        type: 'addLoan',
        ...data,
      }),
    [addEvent]
  )

  const deleteLoan = useCallback<LoanOperations['onDeleteLoan']>(
    (loanId, onCompleted) =>
      addEvent(
        {
          type: 'deleteLoan',
          loanId,
        },
        onCompleted
      ),
    [addEvent]
  )

  const updateInvestment = useCallback<InvestmentOperations['onUpdateInvestment']>(
    ({ investmentId, ...investment }) =>
      addEvent({
        type: 'updateInvestment',
        investmentId,
        investment,
      }),
    [addEvent]
  )

  const addInvestment = useCallback<InvestmentOperations['onAddInvestment']>(
    ({ investmentId, ...investment }) =>
      addEvent({
        type: 'addInvestment',
        investmentId,
        investment: {
          ...investment,
          createdAt: new Date(),
        },
      }),
    [addEvent]
  )

  const deleteInvestment = useCallback<InvestmentOperations['onDeleteInvestment']>(
    (investmentId, onCompleted) =>
      addEvent(
        {
          type: 'deleteInvestment',
          investmentId,
        },
        onCompleted
      ),
    [addEvent]
  )

  const updateMilkQuota = useCallback<MilkOperations['onUpdateQuota']>(
    (data) => addEvent({ type: 'updateMilkQuota', ...data }),
    [addEvent]
  )

  const updateMilkIncome = useCallback<MilkOperations['onUpdateIncome']>(
    ({ year, values }) =>
      addEvent({ type: 'updateMultipleValue', year, values: mapValues(values, (value) => (value ? { value } : null)) }),
    [addEvent]
  )

  const updateMilkExpenses = useCallback<MilkOperations['onUpdateExpenses']>(
    ({ year, values }) =>
      addEvent({ type: 'updateMultipleValue', year, values: mapValues(values, (value) => (value ? { value } : null)) }),
    [addEvent]
  )

  return useMemo(
    () => ({
      duplicate: () => handler.duplicateScenario(budgetId, scenarioId, modificationTimestamp()),
      update: (data: Omit<ScenarioUpdateData, 'lastModification'>) =>
        handler.updateScenario(budgetId, scenarioId, { ...data, lastModification: modificationTimestamp() }),
      updateSelectedReferenceYear,
      addEvent,
      updateCell,
      updateReference,
      updateLoan,
      createLoan,
      deleteLoan,
      updateInvestment,
      addInvestment,
      deleteInvestment,
      updateMilkQuota,
      updateMilkIncome,
      updateMilkExpenses,
    }),
    [
      updateSelectedReferenceYear,
      addEvent,
      updateCell,
      updateReference,
      updateLoan,
      createLoan,
      deleteLoan,
      updateInvestment,
      addInvestment,
      deleteInvestment,
      updateMilkQuota,
      updateMilkIncome,
      updateMilkExpenses,
      handler,
      budgetId,
      scenarioId,
      modificationTimestamp,
    ]
  )
}
