import { type MilkConfigurationViewProps } from '@via/components'

import { useBudgetCallback } from '../../actions/useBudgetCallback.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useScenarioLayoutPage } from '../scenario/useScenarioLayoutPage.ts'

export const useMilkConfigurationPage = (): MilkConfigurationViewProps => {
  const props = useScenarioLayoutPage('milk-configuration')
  const { updateMilkQuota, updateMilkIncome, updateMilkExpenses } = useCurrentScenarioHandler()

  const onUpdateQuota = useBudgetCallback('onUpdateQuota', updateMilkQuota)
  const onUpdateIncome = useBudgetCallback('onUpdateIncome', updateMilkIncome)
  const onUpdateExpenses = useBudgetCallback('onUpdateExpenses', updateMilkExpenses)

  return {
    ...props,
    onUpdateQuota,
    onUpdateIncome,
    onUpdateExpenses,
  }
}
