import { type ScenarioState } from '@via/compute'

import { overheadsSheet2024Blueprint } from './2024/overheadsSheet2024Blueprint.ts'
import { type InferMonetaryTableBlueprint } from './inferMonetaryTableBlueprint.ts'

export type OverheadBlueprint = InferMonetaryTableBlueprint<typeof overheadsSheet2024Blueprint>

export const overheadBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...overheadsSheet2024Blueprint }
  }
}
