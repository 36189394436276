import { type FC, type PropsWithChildren } from 'react'

import { Link, useParentMatches } from '@tanstack/react-router'

import { type ScenarioNavigationId } from './ScenarioNavigationId.ts'

export interface ScenarioLinkProps {
  readonly page: ScenarioNavigationId
  readonly disabled?: boolean
  readonly className?: string
}

export const ScenarioLink: FC<PropsWithChildren<ScenarioLinkProps>> = ({ page, disabled, className, children }) => {
  const parents = useParentMatches()
  const from = parents.at(-1)?.routeId as
    | `/budgets/$budgetId/scenarios/$scenarioId`
    | `/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId`
  if (!from) {
    return null
  }

  if (
    page === 'food' ||
    page === 'crops-configuration' ||
    page === 'maple-results' ||
    page === 'maple-configuration' ||
    page === 'calf-results' ||
    page === 'calf-configuration'
  ) {
    return null
  }

  return (
    <Link className={className} from={from} to={page} disabled={disabled}>
      {children}
    </Link>
  )
}
