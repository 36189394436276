import { createContext } from 'react'

import { noop } from 'lodash-es'

export interface MonetaryTableCollapseSetterContextValue {
  setSectionOpen: (id: string, open: boolean) => void
  setOpen: (id: string, open: boolean) => void
}

export const MonetaryTableCollapseSetterContext = createContext<MonetaryTableCollapseSetterContextValue>({
  setSectionOpen: noop,
  setOpen: noop,
})
