import { type FC } from 'react'

import { FinanceView } from '@via/components'

import { useScenarioFinancePage } from './useScenarioFinancePage.ts'

export const ScenarioFinancePage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioFinancePage()
  return <FinanceView key={props.scenario._id} snapshot={snapshot} {...props} />
}
