import { type DataKey } from '@via/schema'

import { type BudgetData, type ScenarioData } from '../../types'
import { MonetaryTable, type MonetaryTableProps } from '../MonetaryTable'

export interface ScenarioMonetaryTableProps<
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
> extends Pick<
    MonetaryTableProps<Key, TableStates, TabValues, Weight>,
    | 'blueprint'
    | 'collapsed'
    | 'collapsedReference'
    | 'currentWeight'
    | 'currentBlueprintState'
    | 'className'
    | 'stickyTop'
    | 'stickyLeft'
    | 'onCollapse'
    | 'onWeightChange'
    | 'onBlueprintStateChange'
    | 'onReferenceChange'
    | 'onUpdateCell'
  > {
  readonly budget: BudgetData
  readonly scenario: ScenarioData
}

export const ScenarioMonetaryTable = <
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
>({
  budget,
  scenario,
  ...props
}: ScenarioMonetaryTableProps<Key, TableStates, TabValues, Weight>) => (
  <MonetaryTable
    referenceType={scenario.referenceType}
    customReferenceLabel={scenario.customReferenceName}
    selectedReferenceYear={scenario.selectedReferenceYear}
    references={scenario.referenceYears}
    projections={scenario.period?.projectionYears}
    readonly={budget.readonly}
    readonlyReferences={budget.readonly || scenario.referenceType !== 'empty'}
    {...props}
  />
)
