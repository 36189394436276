import { type FC } from 'react'

import { RepaymentCapacityView } from '@via/components'

import { useScenarioRepaymentCapacityPage } from './useScenarioRepaymentCapacityPage.ts'

export const ScenarioRepaymentCapacityPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioRepaymentCapacityPage()
  return <RepaymentCapacityView key={props.scenario._id} snapshot={snapshot} {...props} />
}
