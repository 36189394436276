import { type FC } from 'react'

import { ScenarioMaintenanceView } from '@via/components'

import { useScenarioMaintenancePage } from './useScenarioMaintenancePage.ts'

export const ScenarioMaintenancePage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioMaintenancePage()
  return <ScenarioMaintenanceView key={props.scenario._id} snapshot={snapshot} {...props} />
}
