import { createFileRoute } from '@tanstack/react-router'

import { ScenarioOverheadsPage } from '../pages/scenarioOverheads/ScenarioOverheadsPage.tsx'

const OverheadsComponent = () => <ScenarioOverheadsPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
)({
  component: OverheadsComponent,
})
