import { type OverridableCellData } from '@via/compute'
import { type YearKey } from '@via/schema'
import { mapValues } from 'lodash-es'

import { type MonetaryCellStatus, type OverridableMonetaryCellData } from '../../MonetaryRowData'

const defaultStatus = (key?: YearKey | 'reference' | 'total'): MonetaryCellStatus | undefined => {
  switch (key) {
    case 'reference':
      return 'imported'
    default:
      return 'default'
  }
}

const overrideToNumber = ({ weight, ...override }: NonNullable<OverridableCellData['override']>) => ({
  ...(weight ? { weight } : {}),
  ...mapValues(override, (v) => (v ? Number(v) : undefined)),
})

export const overridableMonetaryCellData = (
  { value, baseValue, override, ...cellData }: OverridableCellData,
  key?: YearKey | 'reference' | 'total'
): OverridableMonetaryCellData => ({
  value: Number(value),
  ...(baseValue ? { baseValue: Number(baseValue) } : {}),
  override: override && overrideToNumber(override),
  status: override ? 'overridden' : defaultStatus(key),
  ...cellData,
})
