import { type FC, useContext } from 'react'

import { LoadingBar } from '../../atoms/LoadingBar/LoadingBar'
import { BudgetReferenceUpdateDialogContext } from '../../context'
import { type NavigationHandler, type ScenarioNavigationId } from '../../navigation'
import { type ScenarioData } from '../../types'
import { BudgetHeader, type BudgetHeaderProps } from '../Header/BudgetHeader'
import { BudgetLogoHeader, type BudgetLogoHeaderProps } from '../Header/BudgetLogoHeader'
import { BudgetReadonlyHeader } from '../Header/BudgetReadonlyHeader'
import { ScenarioTabList } from '../ScenarioTabList/ScenarioTabList'

export type ScenarioMainHeaderProps = Omit<BudgetLogoHeaderProps, 'currentPage'> &
  BudgetHeaderProps & {
    readonly currentScenarioNavigationId: ScenarioNavigationId | 'create' | 'update'
    readonly loading?: boolean
    readonly newScenarioId?: string
    readonly newScenarioName?: string
    readonly scenario?: ScenarioData
    readonly syncRequired?: boolean
    readonly onNavigationChange: NavigationHandler
    readonly onAddScenarioClick?: () => Promise<void>
    readonly onUpdateScenarioReferences?: () => Promise<void> | void
  }

export const ScenarioMainHeader: FC<ScenarioMainHeaderProps> = ({
  budget,
  currentScenarioNavigationId,
  offline,
  loading,
  newScenarioId,
  newScenarioName,
  scenario,
  syncRequired,
  onUpdateBudget,
  onAddScenarioClick,
  onCloseBudget,
  onNavigationChange,
  onRequestOwnership,
  onUpdateScenarioReferences,
}) => {
  const { open } = useContext(BudgetReferenceUpdateDialogContext)
  const onAddScenarioClickWrapper = (): Promise<void> | void => {
    if (syncRequired) {
      open({
        onConfirm: async () => {
          await onUpdateScenarioReferences?.()
          await onAddScenarioClick?.()
        },
      })
      return
    }

    // eslint-disable-next-line consistent-return
    return onAddScenarioClick?.()
  }

  return (
    <>
      <div className="fixed inset-x-0 top-0 z-30 gap-2">
        <div
          className={`flex h-[84px] items-center gap-4 overflow-hidden pl-4 pt-3 ${budget.readonly ? 'bg-yellow-100' : 'bg-sage-100'}`}>
          <BudgetLogoHeader currentPage="scenarios" budget={budget} offline={offline} />
          <div className="flex flex-1 flex-col overflow-hidden">
            <div className="pr-4 pt-1">
              <BudgetHeader
                onUpdateBudget={onUpdateBudget}
                budget={budget}
                offline={offline}
                onCloseBudget={onCloseBudget}
                onRequestOwnership={onRequestOwnership}
              />
            </div>
            <div className="flex h-11 items-end justify-between">
              <div className="flex flex-1 overflow-x-scroll" style={{ scrollbarWidth: 'none' }}>
                <ScenarioTabList
                  newScenarioId={newScenarioId}
                  newScenarioName={newScenarioName}
                  tabId={scenario?._id}
                  budget={budget}
                  onAddScenarioClick={currentScenarioNavigationId !== 'create' ? onAddScenarioClickWrapper : undefined}
                  onNavigationChange={onNavigationChange}
                />
              </div>
              <div className="flex h-full">
                <BudgetReadonlyHeader offline={offline} budget={budget} />
              </div>
            </div>
          </div>
        </div>
        <div className="-mt-0.5 h-0.5 bg-sage-300" />
        <div className="z-40 mt-[-84px] h-2">{loading && <LoadingBar />}</div>
      </div>
      <div className="z-20 h-[84px]" />
    </>
  )
}
