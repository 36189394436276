import { useMemo } from 'react'

import { useBudget } from '@via/components'

import { useNoopCallback } from './useNoopCallback.ts'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VoidCallback = (...args: any[]) => Promise<void> | void

export const useBudgetCallback = <T extends VoidCallback>(name: string, callback: T): T => {
  const budget = useBudget()
  const noop = useNoopCallback(name) as T
  return useMemo<T>(() => (!budget.readonly ? callback : noop), [budget.readonly, callback, noop])
}
