import {
  type AssetComputationType,
  type InventoryComputationType,
  type InventoryTotalComputationType,
  type InvestmentComputationType,
  type LoanTotalComputationType,
  type MilkIncomeComputationType,
  type MilkQuotaComputationType,
  type MovementComputationType,
} from '../../../enums'
import { type DataKey } from '../../data-key-zod'

export type ComputationReferenceSource =
  | 'finance-loan-total'
  | 'finance-asset-total'
  | 'movement-overridable'
  | 'movement-total'
  | 'inventory'
  | 'inventory-total'
  | 'finance-investment'
  | 'finance-investment-total'
  | 'milk-quota'
  | 'milk-income'

type CompositeValue<Source extends ComputationReferenceSource = ComputationReferenceSource> =
  Source extends 'finance-loan-total'
    ? LoanTotalComputationType
    : Source extends 'finance-investment-total'
      ? InvestmentComputationType
      : Source extends 'finance-asset-total'
        ? AssetComputationType
        : Source extends 'movement-overridable' | 'movement-total'
          ? MovementComputationType
          : Source extends 'milk-quota'
            ? MilkQuotaComputationType
            : Source extends 'milk-income'
              ? MilkIncomeComputationType
              : Source extends 'inventory'
                ? InventoryComputationType
                : Source extends 'inventory-total'
                  ? InventoryTotalComputationType
                  : never

export interface ComputationReferenceDataKeyAttribute<
  Source extends ComputationReferenceSource = ComputationReferenceSource,
> {
  readonly type: 'computation-reference'
  readonly source: DataKey
  readonly readonly: boolean
  readonly value: CompositeValue<Source>
}

export const ComputationReferenceDataKeys = [
  'milk-quota.starting-balance',
  'milk-quota.purchase',
  'milk-quota.sale',
  'milk-quota.adjustment',
  'milk-quota.balance',
  'milk-quota.rental',
  'milk-quota.start-up',
  'milk-quota.effective',
  'milk-quota.loan',
  'milk-quota.federation-extension',
  'milk-quota.tolerance-excess',
  'milk-quota.tolerance-shortage',
  'milk-income.base-price',
  'milk-income.bonus',
  'milk-income.price',
  'milk-income.dividends.gross',
  'milk-income.bio-bonus',
  'milk-income.bonus.bio',
  'milk-income.bonus.quality',
  'milk-income.bonus.other',
  'milk.quota.tolerance.kilogram-of-fat.starting-balance',
  'milk.quota.tolerance.kilogram-of-fat.balance',
  'inventory.milk.total.disbursement',
  'inventory.milk.total.value',
  'inventory.milk.total.value-movement-unit-value',
] as const

const MilkQuotaComputationReferenceDataKeyAttributes = {
  'milk-quota.starting-balance': {
    source: 'milk-quota',
    type: 'computation-reference',
    readonly: false,
    value: 'startingBalance',
  },
  'milk-quota.purchase': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'purchase' },
  'milk-quota.sale': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'sale' },
  'milk-quota.adjustment': {
    source: 'milk-quota',
    type: 'computation-reference',
    readonly: false,
    value: 'adjustmentAmount',
  },
  'milk-quota.balance': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'balance' },
  'milk-quota.rental': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'rental' },
  'milk-quota.start-up': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'startUp' },
  'milk-quota.effective': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'effective' },
  'milk-quota.loan': { source: 'milk-quota', type: 'computation-reference', readonly: false, value: 'loanAmount' },
  'milk-quota.federation-extension': {
    source: 'milk-quota',
    type: 'computation-reference',
    readonly: false,
    value: 'federationExtension',
  },
  'milk-quota.tolerance-excess': {
    source: 'milk-quota',
    type: 'computation-reference',
    readonly: false,
    value: 'toleranceExcess',
  },
  'milk-quota.tolerance-shortage': {
    source: 'milk-quota',
    type: 'computation-reference',
    readonly: false,
    value: 'toleranceShortage',
  },
} as const satisfies Record<string, ComputationReferenceDataKeyAttribute<'milk-quota'>>

const MilkIncomeComputationReferenceDataKeyAttributes = {
  'milk-income.base-price': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'basePrice',
  },
  'milk-income.bonus': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'applicableBonus',
  },
  'milk-income.price': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'price',
  },
  'milk-income.dividends.gross': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'grossDividends',
  },
  'milk-income.bio-bonus': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'applicableBonusBio',
  },
  'milk-income.bonus.bio': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'applicableBonusBio',
  },
  'milk-income.bonus.quality': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'applicableBonusQuality',
  },
  'milk-income.bonus.other': {
    source: 'milk-income',
    type: 'computation-reference',
    readonly: false,
    value: 'applicableBonusOther',
  },
} as const satisfies Record<string, ComputationReferenceDataKeyAttribute<'milk-income'>>

const MovementComputationReferenceDataKeyAttributes = {
  'milk.quota.tolerance.kilogram-of-fat.starting-balance': {
    source: 'milk.quota.tolerance.kilogram-of-fat',
    type: 'computation-reference',
    readonly: false,
    value: 'startingBalance',
  },
  'milk.quota.tolerance.kilogram-of-fat.balance': {
    source: 'milk.quota.tolerance.kilogram-of-fat',
    type: 'computation-reference',
    readonly: false,
    value: 'balance',
  },
} as const satisfies Record<string, ComputationReferenceDataKeyAttribute<'movement-overridable' | 'movement-total'>>

const InventoryTotalComputationReferenceDataKeyAttributes = {
  'inventory.milk.total.disbursement': {
    source: 'inventory.milk.total',
    type: 'computation-reference',
    readonly: false,
    value: 'disbursement',
  },
  'inventory.milk.total.value': {
    source: 'inventory.milk.total',
    type: 'computation-reference',
    readonly: false,
    value: 'value',
  },
  'inventory.milk.total.value-movement-unit-value': {
    source: 'inventory.milk.total',
    type: 'computation-reference',
    readonly: false,
    value: 'valueMovementUnitValue',
  },
} as const satisfies Record<string, ComputationReferenceDataKeyAttribute<'inventory-total'>>

export const ComputationReferenceDataKeyAttributes = {
  ...MilkQuotaComputationReferenceDataKeyAttributes,
  ...MilkIncomeComputationReferenceDataKeyAttributes,
  ...MovementComputationReferenceDataKeyAttributes,
  ...InventoryTotalComputationReferenceDataKeyAttributes,
} as const satisfies Record<(typeof ComputationReferenceDataKeys)[number], ComputationReferenceDataKeyAttribute>
