import { useContext, useMemo } from 'react'

import { type RowComputationType, type RowData } from '@via/compute'
import { type ComputationTypeDataKey, type DataKey } from '@via/schema'

import { ScenarioRowsContext } from './ScenarioRowsContext.ts'

export const useScenarioSingleRowData = <Computation extends RowComputationType>(
  dataKey: ComputationTypeDataKey<Computation>
): RowData<Computation> | undefined => {
  const { rows } = useContext(ScenarioRowsContext)
  // Help Typescript excessive computation
  const simplerDataKey = dataKey as unknown as DataKey
  const row = useMemo(() => rows.find(({ key }) => key === simplerDataKey), [simplerDataKey, rows])
  return row as RowData<Computation> | undefined
}
