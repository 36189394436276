import { useContext, useEffect } from 'react'

import { useMeasure } from '@react-hookz/web/useMeasure/index.js'

import { ScenarioLayoutFooterHeightSetterContext } from '../../context/layout/ScenarioLayoutFooterHeightSetterContext.ts'

import { MonetaryTableBlueprintContext } from './contexts/MonetaryTableBlueprintContext.ts'
import { MonetaryTablePropsContext } from './contexts/MonetaryTablePropsContext.ts'
import { MonetaryTableBody } from './MonetaryTableBody.tsx'

export const MonetaryTableSummary = () => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const { stickyLeft } = useContext(MonetaryTablePropsContext)
  const setFooterHeight = useContext(ScenarioLayoutFooterHeightSetterContext)
  const [measurements, ref] = useMeasure<HTMLElement>()
  useEffect(() => {
    if (measurements) {
      const PADDING = 34
      setFooterHeight(measurements.height + PADDING)
    } else {
      setFooterHeight(0)
    }
  }, [measurements, setFooterHeight])

  return (
    <section
      id="sticky-monetary-table-summary"
      ref={ref}
      className="summary-section sticky bottom-0 z-50 min-w-[100vw] border-t-2 border-t-white bg-sage-50 pb-5 pr-4 pt-3 print:static"
      style={{
        marginLeft: stickyLeft * -1,
        paddingLeft: stickyLeft,
      }}>
      <div className="flex flex-row">
        <div
          id="sticky-monetary-table-summary-filler"
          style={{
            marginLeft: stickyLeft * -1,
            minWidth: stickyLeft,
          }}
          className="sticky left-0 z-50 min-h-full bg-sage-50"
        />
        <div className="flex flex-col">
          <MonetaryTableBody lines={blueprint.summary} className="summary-section-line bg-sage-50" />
        </div>
      </div>
    </section>
  )
}
