import { type Except } from 'type-fest'

import { type BlueprintTable } from '../blueprint'
import { dataLine, dividerLine } from '../utils'

export const overheadsSheet2024Blueprint = {
  sections: [
    {
      id: 'overheads',
      titleKey: 'table.overheads',
      children: [
        {
          ...dividerLine({ border: 'top' }, 'overheads'),
          children: [
            dataLine('overheads.general.electricity', { displayAsProjection: true }),
            dataLine('overheads.general.phone', { displayAsProjection: true }),
            dataLine('overheads.general.short-term-interest', { displayAsProjection: true }),
            dataLine('overheads.general.taxes', { displayAsProjection: true }),
            dataLine('overheads.general.insurance.general', { displayAsProjection: true }),
            dataLine('overheads.general.insurance.agri', { displayAsProjection: true }),
            dataLine('overheads.general.administration', { displayAsProjection: true }),
            dataLine('overheads.general.fees', { displayAsProjection: true }),
            {
              ...dataLine('overheads.expenses.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('overheads.expenses.administration', { displayAsProjection: true }),
                dataLine('overheads.expenses.miscellaneous', { displayAsProjection: true }),
                dataLine('overheads.expenses.secondary-maple', { displayAsProjection: true }),
                dataLine('overheads.expenses.secondary-wooded-area', { displayAsProjection: true }),
                dataLine('overheads.expenses.other-productions', { displayAsProjection: true }),
              ],
            },
            {
              ...dataLine('overheads.environmental-fees.total', { variant: 'subSectionProduction' }),
              children: [
                dataLine('overheads.environmental-fees.analysis.soil', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.analysis.manure', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.balance.food', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.balance.phosphorus', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.plan', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.notarial-fees', { displayAsProjection: true }),
                dataLine('overheads.environmental-fees.other', { displayAsProjection: true }),
              ],
            },
          ],
        },
        dataLine('overheads.total', { variant: 'total', closingSection: true }),
      ],
    },
  ],
  summary: [
    dataLine('overheads.total', { variant: 'finalSubTotal' }),
    dataLine('overheads-salaries.total', { variant: 'finalTotal', symbol: 'total' }),
  ],
  columns: [
    { id: 'title', type: 'title', sticky: true },
    {
      id: 'reference',
      type: 'reference',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['selected-reference'],
    },
    {
      id: 'references',
      type: 'all-references',
      sticky: true,
      className: 'w-24',
      withDividerRight: true,
      displayOnTableState: ['references'],
    },
    {
      id: 'divider',
      type: 'divider',
      className: 'w-1',
      displayOnTableState: ['selected-reference', 'references'],
    },
    {
      id: 'projections',
      type: 'result-projections',
      withDividerRight: true,
      className: 'w-24',
    },
  ],
  defaultState: 'selected-reference',
  expendedState: 'references',
} as const satisfies Except<BlueprintTable<'selected-reference' | 'references'>, 'version'>
