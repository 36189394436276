import { DataKeyZod } from '@via/schema'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { type MonetaryRowData } from '../../model'
import { InlineEditingCellForm } from '../../molecules/InlineEditingCell/InlineEditingCellForm.tsx'
import { MonetaryOverrideWidget } from '../../monetary/MonetaryOverrideWidget/MonetaryOverrideWidget.tsx'

import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

interface EditableReferenceValueCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly rowData: MonetaryRowData
  readonly onUpdateCell: UpdateCellValue<'reference'>
}

export const EditableReferenceValueCell = ({
  rowData,
  cellData,
  onUpdateCell,
  className,
  ...props
}: EditableReferenceValueCellProps) => (
  <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
    {({ type, onClose }) =>
      type === 'inline' ? (
        <InlineEditingCellForm
          value={cellData.value}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'reference', dataKey: DataKeyZod.parse(rowData.key), override: { value } })
            onClose()
          }}
          className={cn('-mt-6', className)}
        />
      ) : (
        <MonetaryOverrideWidget
          operationDisabled
          value={cellData}
          onConfirm={async (value) => {
            await onUpdateCell({ type: 'reference', dataKey: DataKeyZod.parse(rowData.key), ...value })
            onClose()
          }}
          onApply={async (value) => {
            await onUpdateCell({ type: 'reference', dataKey: DataKeyZod.parse(rowData.key), ...value })
          }}
        />
      )
    }
  </PopoverEditableValueCell>
)
