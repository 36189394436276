import { useContext } from 'react'

import { ReportFileContext, type ScenarioNavigationId, useBudget, useScenario } from '@via/components'

import { useScenarioPageActions } from '../../actions/useScenarioPageActions.ts'
import { useOfflineState } from '../../contexts/offlineState/useOfflineState.ts'
import { useSyncRequired } from '../../contexts/syncRequired/useSyncRequired.ts'
import { useScenarioMonetaryLocalState } from '../../state/useScenarioMonetaryLocalState.ts'
import { useScenarioNavigationLocalState } from '../../state/useScenarioNavigationLocalState.ts'

export const useScenarioLayoutPage = (navigationId: ScenarioNavigationId) => {
  const { offline } = useOfflineState()
  const budget = useBudget()
  const scenario = useScenario()
  const syncRequired = useSyncRequired()
  const file = useContext(ReportFileContext)
  const localState = useScenarioNavigationLocalState()
  const scenarioLocalState = useScenarioMonetaryLocalState(budget._id, scenario._id, navigationId)
  const actions = useScenarioPageActions()

  return {
    offline,
    budget,
    scenario,
    syncRequired,
    snapshotName: file?.fileName,
    ...localState,
    ...scenarioLocalState,
    ...actions,
  }
}
