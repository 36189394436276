import { createFileRoute } from '@tanstack/react-router'

import { MilkConfigurationPage } from '../pages/milkConfiguration/MilkConfigurationPage.tsx'

const MilkConfigurationComponent = () => <MilkConfigurationPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
)({
  component: MilkConfigurationComponent,
})
