import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import { Label } from '../../atoms/Label/Label'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import {
  ScenarioMonetaryLayout,
  type ScenarioMonetaryLayoutProps,
} from '../../organisms/ScenarioMonetaryLayout/ScenarioMonetaryLayout.tsx'

import { useBalanceSheetBlueprint } from './useBalanceSheetBlueprint'

export type BalanceSheetViewProps = Except<
  ScenarioMonetaryLayoutProps,
  'currentScenarioNavigationId' | 'collapsedReference' | 'blueprint'
>

export const BalanceSheetView = ({ scenario, ...props }: BalanceSheetViewProps) => {
  const intl = useIntl()
  const blueprint = useBalanceSheetBlueprint(scenario)

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMonetaryLayout
        currentScenarioNavigationId="balance"
        collapsedReference="latest"
        scenario={scenario}
        blueprint={blueprint}
        {...props}>
        <div className="flex">
          <div className="sticky left-36 pl-8 pt-6">
            <Label className="text-2xl font-bold uppercase text-black">
              {intl.formatMessage({ id: 'balanceSheet.name' })}
            </Label>
          </div>
        </div>
      </ScenarioMonetaryLayout>
    </MonetaryTableEditingStateProvider>
  )
}
