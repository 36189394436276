import { type FC } from 'react'

import { ScenarioResultsView } from '@via/components'

import { useScenarioResultsPage } from './useScenarioResultsPage.ts'

export const ScenarioResultsPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioResultsPage()
  return <ScenarioResultsView key={props.scenario._id} snapshot={snapshot} {...props} />
}
