import { useBudget } from '@via/components'

import { useCurrentBudgetHandler } from '../contexts/currentBudget/useCurrentBudgetHandler.ts'
import { useCurrentScenarioHandler } from '../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useAppNavigation } from '../navigation/useAppNavigation.ts'

import { useNoopCallback } from './useNoopCallback.ts'

export const useScenarioPageActions = () => {
  const { onNavigationChange } = useAppNavigation()
  const budget = useBudget()
  const {
    close: onCloseBudget,
    requestOwnership: onRequestOwnership,
    update: onUpdateBudget,
    updateScenarioReferences: onUpdateScenarioReferences,
  } = useCurrentBudgetHandler()
  const onUpdateBudgetNoop = useNoopCallback('onUpdateBudget')
  const onUpdateScenarioReferencesNoop = useNoopCallback('onUpdateScenarioReferences')

  const { updateReference: onRefreshScenarioReference, updateCell: onUpdateCell } = useCurrentScenarioHandler()
  const onRefreshScenarioReferenceNoop = useNoopCallback('onRefreshScenarioReference')
  const onUpdateCellNoop = useNoopCallback('onUpdateCell')

  return {
    onNavigationChange,
    onCloseBudget,
    onRequestOwnership,
    onUpdateBudget: !budget.readonly ? onUpdateBudget : onUpdateBudgetNoop,
    onUpdateScenarioReferences: !budget.readonly ? onUpdateScenarioReferences : onUpdateScenarioReferencesNoop,
    onRefreshScenarioReference: !budget.readonly ? onRefreshScenarioReference : onRefreshScenarioReferenceNoop,
    onUpdateCell: !budget.readonly ? onUpdateCell : onUpdateCellNoop,
  }
}
