import { type ScenarioState } from '@via/compute'

import { cropsResultsUdpBlueprint } from '../../blueprints/cropsResultsUdpBlueprint.ts'
import { useScenarioStateMemo } from '../../hooks'
import { useMonetaryTableUdpBlueprint } from '../../hooks/useMonetaryTableUdpBlueprint.ts'

export const useCropsResultsUdpBlueprint = (state: ScenarioState) => {
  const blueprint = useScenarioStateMemo(cropsResultsUdpBlueprint, state, ['blueprintVersion'])
  return useMonetaryTableUdpBlueprint(blueprint)
}
