import { createFileRoute } from '@tanstack/react-router'

import { ScenarioRepaymentCapacityPage } from '../pages/scenarioRepaymentCapacity/ScenarioRepaymentCapacityPage'

const RepaymentComponent = () => <ScenarioRepaymentCapacityPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
)({
  component: RepaymentComponent,
})
