import { createFileRoute } from '@tanstack/react-router'

import { ScenarioResultsPage } from '../pages/scenarioResults/ScenarioResultsPage'

const ResultsComponent = () => <ScenarioResultsPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
)({
  component: ResultsComponent,
})
