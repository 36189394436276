import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { Icons } from '../../atoms'
import { FormControl } from '../../atoms/Form/FormControl'
import { FormField } from '../../atoms/Form/FormField'
import { FormItem } from '../../atoms/Form/FormItem'
import { FormMessage } from '../../atoms/Form/FormMessage'
import { FormSubmitButtons } from '../../atoms/Form/FormSubmitButtons'
import { Textarea } from '../../atoms/Textarea/Textarea'
import { cn } from '../../lib/utils'
import { AdjustmentInput } from '../../molecules/AdjustmentInput/AdjustmentInput'
import { FormatedNumberReadonlyInputWithLabel } from '../../molecules/FormatedNumberReadonlyInputWithLabel/FormatedNumberReadonlyInputWithLabel.tsx'
import { type ScenarioNavigationId } from '../../navigation'
import { ScenarioLink } from '../../navigation/ScenarioLink.tsx'
import { type MonetaryCellFormData } from '../MonetaryCellFormZod.ts'
import {
  MonetaryWidgetFormSubmitProvider,
  type MonetaryWidgetFormSubmitProviderProps,
} from '../MonetaryWidgetFormSubmitProvider'

export interface OverridableProjectionWidgetProps extends MonetaryWidgetFormSubmitProviderProps {
  readonly baseValueLabel: string
  readonly navigationId?: ScenarioNavigationId
}

export const OverridableProjectionWidget = forwardRef<HTMLFormElement, OverridableProjectionWidgetProps>(
  ({ className, value, baseValueLabel, navigationId, ...props }, ref) => {
    const intl = useIntl()

    return (
      <MonetaryWidgetFormSubmitProvider
        value={value}
        className={cn(
          'flex w-32 flex-col items-start gap-2.5 border-2 border-white bg-green-200 p-2.5 shadow-md',
          className
        )}
        ref={ref}
        {...props}>
        <div className="flex flex-col items-start gap-3">
          {navigationId && (
            <div className="flex w-full select-none items-center justify-end gap-1 text-black">
              <p className="text-xxs font-bold leading-tight tracking-[-0.1px]">
                {intl.formatMessage({ id: 'balanceSheet.projectionWidget.seeOrigin' })}
              </p>
              <ScenarioLink page={navigationId}>
                <Icons.External />
              </ScenarioLink>
            </div>
          )}

          <div className="flex w-full flex-col gap-1.5">
            <FormatedNumberReadonlyInputWithLabel
              variant="external"
              label={baseValueLabel}
              dimension="xs"
              align="right"
              tabIndex={-1}
              value={value?.baseValue}
            />
          </div>

          <FormField<MonetaryCellFormData, 'override'>
            name="override"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <AdjustmentInput
                    label={intl.formatMessage({ id: 'financialResults.projectionWidget.adjustment' })}
                    dimension="xs"
                    align="right"
                    allowedOperators={['+', '-']}
                    autoFocus
                    onFocus={(e) => {
                      e.target.select()
                    }}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField<MonetaryCellFormData, 'note'>
            name="note"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormControl>
                  <Textarea
                    placeholder={intl.formatMessage({ id: 'financialResults.projectionWidget.notes.placeholder' })}
                    rows={5}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormSubmitButtons className="flex w-full items-start justify-center gap-2" />
      </MonetaryWidgetFormSubmitProvider>
    )
  }
)
