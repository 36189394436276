import { type ScenarioState } from '@via/compute'

import { scenariosResults2024Blueprint } from './2024/scenariosResults2024Blueprint.ts'
import { type InferMonetaryTableBlueprint } from './inferMonetaryTableBlueprint.ts'

export type ScenarioResultsBlueprint = InferMonetaryTableBlueprint<ReturnType<typeof scenariosResults2024Blueprint>>

export const scenarioResultsBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...scenariosResults2024Blueprint(state) }
  }
}
