import { addBreadcrumb, captureMessage } from '@sentry/react'
import { detailedDiff } from 'deep-object-diff'
import { isRxDocument, type RxCollection } from 'rxdb'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DocumentData = Record<string, any>

function verifyDate(data: unknown, collectionName: string) {
  function verifyProperty(value: unknown, path = '') {
    if (value instanceof Date) {
      if (import.meta.env.DEV) {
        // eslint-disable-next-line no-console
        console.warn('Date found in data', { path, value, data, collectionName })
      }
      captureMessage('Date found in data', { contexts: { data: { path, value, data, collectionName } } })
    } else if (Array.isArray(value)) {
      value.forEach((v, index) => {
        verifyProperty(v, `${path}[${index}]`)
      })
    } else if (value instanceof Object) {
      Object.entries(value).forEach(([key, v]) => {
        verifyProperty(v, path ? `${path}.${key}` : key)
      })
    }
  }

  verifyProperty(data)
}

export const registerRxCollectionBreadcrumbs = <
  RxDocumentType extends DocumentData,
  OrmMethods,
  StaticMethods,
  InstanceCreationOptions,
>(
  collection: RxCollection<RxDocumentType, OrmMethods, StaticMethods, InstanceCreationOptions>
) => {
  const addRxBreadcrumb = (call: string, data: Record<string, unknown>) => {
    addBreadcrumb({
      type: 'debug',
      level: 'debug',
      category: 'rxdb',
      message: `${collection.name} ${call}`,
      data,
    })
  }

  collection.preInsert((data) => {
    verifyDate(data, collection.name)
    addRxBreadcrumb('preInsert', { data })
  }, true)

  collection.preSave((data, rxDocument) => {
    const before = isRxDocument(rxDocument) ? rxDocument.toJSON() : rxDocument
    const diff = import.meta.env.MODE !== 'production' ? detailedDiff(before, data) : 'not in production mode'
    verifyDate(data, collection.name)
    addRxBreadcrumb(`preSave (${data._id})`, { data, rxDocument: before, diff })
  }, true)

  collection.preRemove((data, rxDocument) => {
    verifyDate(data, collection.name)
    addRxBreadcrumb(`preRemove (${data._id})`, {
      data,
      rxDocument: isRxDocument(rxDocument) ? rxDocument.toJSON() : rxDocument,
    })
  }, true)

  collection.postInsert((data, rxDocument) => {
    verifyDate(data, collection.name)
    addRxBreadcrumb(`postInsert (${data._id})`, {
      data,
      rxDocument: isRxDocument(rxDocument) ? rxDocument.toJSON() : rxDocument,
    })
  }, true)

  collection.postSave((data, rxDocument) => {
    verifyDate(data, collection.name)
    addRxBreadcrumb(`postSave (${data._id})`, {
      data,
      rxDocument: isRxDocument(rxDocument) ? rxDocument.toJSON() : rxDocument,
    })
  }, true)

  collection.postRemove((data, rxDocument) => {
    verifyDate(data, collection.name)
    addRxBreadcrumb(`postRemove (${data._id})`, {
      data,
      rxDocument: isRxDocument(rxDocument) ? rxDocument.toJSON() : rxDocument,
    })
  }, true)
}
