import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { Label } from '../../atoms/Label/Label.tsx'
import { MonetaryTableEditingStateProvider } from '../../context/editing/MonetaryTableEditingStateProvider.tsx'
import { type NavigationHandler } from '../../navigation'
import { MonetaryTable, type MonetaryTableActions } from '../../organisms'
import {
  ScenarioMainLayout,
  type ScenarioMainLayoutProps,
} from '../../organisms/ScenarioMainLayout/ScenarioMainLayout.tsx'
import { type BudgetData, type ScenarioData } from '../../types.ts'

import { useMilkAnimalsBlueprint } from './useMilkAnimalsBlueprint.ts'

export interface MilkAnimalsViewProps
  extends Pick<
      ScenarioMainLayoutProps,
      | 'loading'
      | 'syncRequired'
      | 'snapshot'
      | 'snapshotName'
      | 'navigationMenuCollapsedState'
      | 'onNavigationMenuCollapse'
      | 'onCloseBudget'
      | 'onRequestOwnership'
      | 'onRefreshScenarioReference'
      | 'onUpdateBudget'
      | 'onUpdateScenarioReferences'
    >,
    Required<Pick<MonetaryTableActions, 'onUpdateCell'>> {
  readonly budget: BudgetData
  readonly scenario: ScenarioData
  readonly onNavigationChange: NavigationHandler
  readonly tabsState?: { inventory?: 'movement' | 'balance' }
  readonly onTabChange?: (section: 'inventory', tab: 'movement' | 'balance') => void
  readonly offline: boolean
}

export const MilkAnimalsView: FC<MilkAnimalsViewProps> = ({
  budget,
  scenario,
  syncRequired,
  offline,
  loading,
  snapshot,
  snapshotName,
  tabsState,
  navigationMenuCollapsedState,
  onNavigationMenuCollapse,
  onCloseBudget,
  onNavigationChange,
  onRequestOwnership,
  onRefreshScenarioReference,
  onTabChange,
  onUpdateScenarioReferences,
  onUpdateBudget,
  onUpdateCell,
  ...tableProps
}) => {
  const intl = useIntl()
  const { averagesBlueprint, animalsBlueprint, movementsBlueprint, inventorySummaryBlueprint } =
    useMilkAnimalsBlueprint(scenario)

  return (
    <MonetaryTableEditingStateProvider>
      <ScenarioMainLayout
        currentScenarioNavigationId="milk-animals"
        budget={budget}
        scenario={scenario}
        syncRequired={syncRequired}
        offline={offline ?? false}
        loading={loading}
        snapshot={snapshot}
        snapshotName={snapshotName}
        navigationMenuCollapsedState={navigationMenuCollapsedState}
        onNavigationMenuCollapse={onNavigationMenuCollapse}
        onCloseBudget={onCloseBudget}
        onRequestOwnership={onRequestOwnership}
        onUpdateBudget={onUpdateBudget}
        onNavigationChange={onNavigationChange}
        onRefreshScenarioReference={onRefreshScenarioReference}
        onUpdateScenarioReferences={onUpdateScenarioReferences}>
        <div className="pb-8 pl-32">
          <div className="sticky left-36 pl-8 pt-6">
            <div className="flex flex-col pr-8">
              <Label className="text-2xl font-normal text-black">
                {intl.formatMessage({ id: 'milk.animals.title' })}
              </Label>
              <Label className="text-2xl font-bold text-black">
                {intl.formatMessage({ id: 'milk.animals.subTitle' })}
              </Label>
            </div>
          </div>
          <MonetaryTable
            collapsedReference="selected"
            referenceType={scenario.referenceType}
            customReferenceLabel={scenario.customReferenceName}
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={averagesBlueprint}
            onUpdateCell={onUpdateCell}
            className="pt-5"
          />
          <MonetaryTable
            collapsedReference="selected"
            referenceType={scenario.referenceType}
            customReferenceLabel={scenario.customReferenceName}
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period?.projectionYears}
            readonly={budget.readonly}
            readonlyReferences
            blueprint={animalsBlueprint}
            onUpdateCell={onUpdateCell}
            className="pt-5"
            {...tableProps}
          />
          <MonetaryTable
            collapsedReference="selected"
            referenceType={scenario.referenceType}
            customReferenceLabel={scenario.customReferenceName}
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={movementsBlueprint}
            currentBlueprintTab={tabsState?.inventory}
            onTabChange={(tab) => onTabChange?.('inventory', tab)}
            onUpdateCell={onUpdateCell}
            className="pt-5"
          />
          <MonetaryTable
            collapsedReference="selected"
            referenceType={scenario.referenceType}
            customReferenceLabel={scenario.customReferenceName}
            selectedReferenceYear={scenario.selectedReferenceYear}
            references={scenario.referenceYears}
            projections={scenario.period.projectionYears}
            readonly={budget.readonly}
            blueprint={inventorySummaryBlueprint}
            className="pt-1"
          />
        </div>
      </ScenarioMainLayout>
    </MonetaryTableEditingStateProvider>
  )
}
