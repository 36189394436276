import { createRef, type HtmlHTMLAttributes, useContext, useMemo } from 'react'

import { type DataKey } from '@via/schema'

import { cn } from '../../lib/utils'

import { type MonetaryTableActions, MonetaryTableActionsContext } from './contexts/MonetaryTableActionsContext.ts'
import {
  MonetaryTableCollapseProvider,
  type MonetaryTableCollapseProviderProps,
} from './contexts/MonetaryTableCollapseProvider.tsx'
import { MonetaryTableCollapseStateContext } from './contexts/MonetaryTableCollapseStateContext.ts'
import { MonetaryTableDividerCell } from './contexts/MonetaryTableNavigationProvider.tsx'
import { MonetaryTablePropsContext, type MonetaryTablePropsContextValue } from './contexts/MonetaryTablePropsContext.ts'
import {
  type MonetaryTableStateContextProviderProps,
  MonetaryTableStateProvider,
} from './contexts/MonetaryTableStateProvider.tsx'
import { MonetaryTableBody } from './MonetaryTableBody'
import { MonetaryTableSectionHeader } from './MonetaryTableSectionHeader.tsx'
import { MonetaryTableSummary } from './MonetaryTableSummary.tsx'
import { type MonetaryTableSection } from './types'

export interface MonetaryTableProps<
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
> extends Partial<MonetaryTablePropsContextValue>,
    MonetaryTableStateContextProviderProps<Key, TableStates, TabValues, Weight>,
    MonetaryTableCollapseProviderProps,
    MonetaryTableActions,
    HtmlHTMLAttributes<HTMLDivElement> {}

const TableSection = ({
  section,
  className,
}: {
  readonly section: MonetaryTableSection
  readonly className?: string
}) => {
  const { isSectionOpen } = useContext(MonetaryTableCollapseStateContext)
  return section.lines.map((line) => (
    <div key={line.id}>
      <MonetaryTableBody lines={[line]} className={className} childrenHidden={!isSectionOpen(section.id)} />
    </div>
  ))
}

export const MonetaryTable = <
  Key extends string = DataKey,
  TableStates extends string = string,
  TabValues extends string = string,
  Weight extends string = string,
>({
  collapsed,
  onCollapse,
  className,
  blueprint,
  currentBlueprintState,
  currentBlueprintTab,
  currentWeight,
  referenceType,
  customReferenceLabel,
  collapsedReference,
  references = [],
  projections = [],
  selectedReferenceYear,
  readonly = false,
  readonlyReferences = readonly,
  stickyTop = 84,
  stickyLeft = 128,
  onUpdateCell,
  onReferenceChange,
  onBlueprintStateChange,
  onTabChange,
  onWeightChange,
  withNavigation = true,
  ...props
}: MonetaryTableProps<Key, TableStates, TabValues, Weight>) => {
  const actions = useMemo(() => ({ onUpdateCell, onReferenceChange }), [onUpdateCell, onReferenceChange])
  const tableRef = createRef<HTMLDivElement>()

  const state = useMemo(
    () => ({
      referenceType,
      customReferenceLabel,
      collapsedReference,
      references,
      projections,
      selectedReferenceYear,
      readonly,
      readonlyReferences,
      stickyTop,
      stickyLeft,
      withNavigation,
    }),
    [
      referenceType,
      customReferenceLabel,
      collapsedReference,
      references,
      projections,
      selectedReferenceYear,
      readonly,
      readonlyReferences,
      stickyTop,
      stickyLeft,
      withNavigation,
    ]
  )

  return (
    <MonetaryTableActionsContext.Provider value={actions as MonetaryTableActions}>
      <MonetaryTablePropsContext.Provider value={state}>
        <MonetaryTableStateProvider
          blueprint={blueprint}
          currentBlueprintState={currentBlueprintState}
          currentBlueprintTab={currentBlueprintTab}
          currentWeight={currentWeight}
          onBlueprintStateChange={onBlueprintStateChange}
          onTabChange={onTabChange}
          onWeightChange={onWeightChange}>
          <MonetaryTableCollapseProvider collapsed={collapsed} onCollapse={onCollapse}>
            <div className={cn('flex flex-1 flex-col justify-between', className)} {...props} ref={tableRef}>
              <MonetaryTableDividerCell containerRef={tableRef}>
                <div className="flex flex-col">
                  {blueprint.sections.map((section, index) => (
                    <section key={section.id} className={section.containerClassName}>
                      <MonetaryTableSectionHeader
                        section={section as MonetaryTableSection}
                        isTopHeader={index === 0}
                        className="bg-white"
                      />
                      <TableSection section={section as MonetaryTableSection} className="bg-white" />
                    </section>
                  ))}
                </div>
                <div className={cn('flex-1', !blueprint.summary ? 'min-h-6' : 'min-h-2')}></div>
                {blueprint.summary && <MonetaryTableSummary />}
              </MonetaryTableDividerCell>
            </div>
          </MonetaryTableCollapseProvider>
        </MonetaryTableStateProvider>
      </MonetaryTablePropsContext.Provider>
    </MonetaryTableActionsContext.Provider>
  )
}
