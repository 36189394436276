import { type FC } from 'react'
import { useIntl } from 'react-intl'

import { type Except } from 'type-fest'

import { Label } from '../../atoms/Label/Label'
import { MilkOperationsProvider, type MilkOperationsProviderProps } from '../../context'
import {
  ScenarioMonetaryLayout,
  type ScenarioMonetaryLayoutProps,
} from '../../organisms/ScenarioMonetaryLayout/ScenarioMonetaryLayout.tsx'

import { useMilkConfigurationBlueprint } from './useMilkConfigurationBlueprint.ts'

export interface MilkConfigurationViewProps
  extends MilkOperationsProviderProps,
    Except<ScenarioMonetaryLayoutProps, 'currentScenarioNavigationId' | 'collapsedReference' | 'blueprint'> {}

export const MilkConfigurationView: FC<MilkConfigurationViewProps> = ({
  scenario,
  onUpdateQuota,
  onUpdateIncome,
  onUpdateExpenses,
  ...props
}) => {
  const intl = useIntl()
  const blueprint = useMilkConfigurationBlueprint(scenario)

  return (
    <MilkOperationsProvider
      onUpdateQuota={onUpdateQuota}
      onUpdateIncome={onUpdateIncome}
      onUpdateExpenses={onUpdateExpenses}>
      <ScenarioMonetaryLayout
        currentScenarioNavigationId="milk-configuration"
        collapsedReference="selected"
        scenario={scenario}
        blueprint={blueprint}
        {...props}>
        <div className="flex">
          <div className="sticky left-36 pl-8 pt-6">
            <div className="flex items-center">
              <div className="flex flex-col pr-8">
                <Label className="text-2xl font-normal text-black">
                  {intl.formatMessage({ id: 'milk.configuration.title' })}
                </Label>
                <Label className="text-2xl font-bold text-black">
                  {intl.formatMessage({ id: 'milk.configuration.subTitle' })}
                </Label>
              </div>
            </div>
          </div>
        </div>
      </ScenarioMonetaryLayout>
    </MilkOperationsProvider>
  )
}
