import { useMemo } from 'react'

import { type DataKey } from '@via/schema'

import { type MonetaryTableBlueprint } from '../MonetaryTable'

import { type MonetaryUdpTableBlueprint } from './types.ts'

export interface UseScenarioUdpBlueprintOptions<TUdpKey extends string = DataKey, TableStates extends string = string> {
  readonly blueprint: MonetaryUdpTableBlueprint<string, TUdpKey, TableStates>
  readonly active?: string
  readonly readonly?: boolean
}

export const useScenarioUdpBlueprint = <TUdpKey extends string = DataKey, TableStates extends string = string>({
  blueprint,
  active,
  readonly = false,
}: UseScenarioUdpBlueprintOptions<TUdpKey, TableStates>) =>
  useMemo<MonetaryTableBlueprint<TUdpKey, TableStates>>(() => {
    const udpElement = active && blueprint.udp[active]
    const name = active && blueprint.udpName[active]
    return {
      sections: [
        {
          id: 'udp',
          noHeader: true,
          lines: udpElement
            ? [
                {
                  id: udpElement,
                  variant: 'udp',
                  name: name ?? udpElement,
                  readonly,
                },
              ]
            : [],
        },
      ],
      ...blueprint,
    }
  }, [active, blueprint, readonly])
