import { forwardRef, type HtmlHTMLAttributes } from 'react'
import { useIntl } from 'react-intl'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { type BlueprintVersion, type ProductionType } from '@via/schema'

import { LineCollapsable } from '../../atoms/LineTitle/LineCollapsable'
import { cn } from '../../lib/utils'

import { ScenarioNavigationMenuLink, type ScenarioNavigationMenuLinkProps } from './ScenarioNavigationMenuLink.tsx'

type ScenarioNavigationProduction = 'milk' | 'crops' | 'maple' | 'calf'
type ScenarioNavigationCollapsedState = Partial<Record<ScenarioNavigationProduction, boolean>>

export interface ScenarioNavigationMenuProps extends HtmlHTMLAttributes<HTMLDivElement> {
  readonly active: 'update' | ScenarioNavigationMenuLinkProps['page']
  readonly blueprintVersion?: BlueprintVersion
  readonly enabledProductions?: ReadonlyArray<ProductionType>
  readonly withConfiguration?: boolean
  readonly collapsed?: ScenarioNavigationCollapsedState
  readonly onCollapse?: (state: ScenarioNavigationCollapsedState) => void
}

const DEFAULT_COLLAPSED_STATE = { milk: false, crops: false, maple: true, calf: true }
export const ScenarioNavigationMenu = forwardRef<HTMLDivElement, ScenarioNavigationMenuProps>(
  (
    {
      className,
      active,
      blueprintVersion,
      enabledProductions = [],
      collapsed: collapsedState,
      withConfiguration = true,
      onCollapse,
    },
    ref
  ) => {
    const intl = useIntl()
    const [collapsed = {}, setCollapsed] = useControllableState<ScenarioNavigationCollapsedState>({
      prop: collapsedState && { ...DEFAULT_COLLAPSED_STATE, ...collapsedState },
      onChange: onCollapse,
      defaultProp: DEFAULT_COLLAPSED_STATE,
    })

    return (
      <div className={cn('flex flex-col items-stretch gap-2', className)} ref={ref}>
        {withConfiguration && (
          <>
            <ScenarioNavigationMenuLink active={active === 'update'} page="configuration" />
            <div className="h-2" />
          </>
        )}

        {(['results', 'repayment', 'finance', 'balance'] as const).map((production) => (
          <ScenarioNavigationMenuLink
            key={`scenario-nav-${production}`}
            active={active === production}
            page={production}
          />
        ))}
        {blueprintVersion === 'complete-2024' && (
          <>
            {/*<ScenarioLink key="scenario-nav-food" page="food" disabled />*/}
            <ScenarioNavigationMenuLink
              key="scenario-nav-maintenance"
              active={active === 'maintenance'}
              page="maintenance"
            />
            <ScenarioNavigationMenuLink key="scenario-nav-overheads" active={active === 'overheads'} page="overheads" />
          </>
        )}
        {blueprintVersion === 'complete-2024' && enabledProductions.includes('milk') && (
          <div>
            <div className="h-2" />
            <button
              type="button"
              className="flex w-full items-center gap-1.5 pb-2 pl-1.5"
              tabIndex={-1}
              onClick={() => {
                setCollapsed((state) => ({
                  ...state,
                  milk: !state?.milk,
                }))
              }}>
              <LineCollapsable open={!collapsed.milk} tabIndex={-1} />
              <p className="text-left text-xs font-medium uppercase">
                {intl.formatMessage({ id: 'scenarioNav.milk' })}
              </p>
            </button>
            {!collapsed.milk && (
              <div className={cn('flex flex-col items-stretch gap-2 pl-2', className)}>
                {(['milk-results', 'milk-configuration', 'milk-animals'] as const).map((production) => (
                  <ScenarioNavigationMenuLink
                    key={`scenario-nav-${production}`}
                    active={active === production}
                    page={production}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {blueprintVersion === 'complete-2024' && enabledProductions.includes('crops') && (
          <div>
            <div className="h-2" />
            <button
              type="button"
              className="flex w-full items-center gap-1.5 pb-2 pl-1.5"
              tabIndex={-1}
              onClick={() => {
                setCollapsed((state) => ({
                  ...state,
                  crops: !state?.crops,
                }))
              }}>
              <LineCollapsable open={!collapsed.crops} tabIndex={-1} />
              <p className="text-left text-xs font-medium uppercase">
                {intl.formatMessage({ id: 'scenarioNav.crops' })}
              </p>
            </button>
            {!collapsed.crops && (
              <div className={cn('flex flex-col items-stretch gap-2 pl-2', className)}>
                {(['crops-results'] as const).map((production) => (
                  <ScenarioNavigationMenuLink
                    key={`scenario-nav-${production}`}
                    active={active === production}
                    page={production}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {blueprintVersion === 'complete-2024' && enabledProductions.includes('maple') && (
          <div>
            <div className="h-2" />
            <button
              type="button"
              className="flex w-full items-center gap-1.5 pb-2 pl-1.5 text-gray-400"
              disabled
              tabIndex={-1}
              onClick={() => {
                setCollapsed((state) => ({
                  ...state,
                  crops: !state?.crops,
                }))
              }}>
              <LineCollapsable open={!collapsed.maple} tabIndex={-1} />
              <p className="text-left text-xs font-medium uppercase text-gray-400">
                {intl.formatMessage({ id: 'scenarioNav.maple' })}
              </p>
            </button>
            {!collapsed.maple && (
              <div className={cn('flex flex-col items-stretch gap-2 pl-2', className)}>
                {(['maple-results', 'maple-configuration'] as const).map((production) => (
                  <ScenarioNavigationMenuLink
                    key={`scenario-nav-${production}`}
                    active={active === production}
                    page={production}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {blueprintVersion === 'complete-2024' && enabledProductions.includes('calf') && (
          <div>
            <div className="h-2" />
            <button
              type="button"
              className="flex w-full items-center gap-1.5 pb-2 pl-1.5 text-gray-400"
              disabled
              tabIndex={-1}
              onClick={() => {
                setCollapsed((state) => ({
                  ...state,
                  crops: !state?.crops,
                }))
              }}>
              <LineCollapsable open={!collapsed.calf} tabIndex={-1} />
              <p className="text-left text-xs font-medium uppercase text-gray-400">
                {intl.formatMessage({ id: 'scenarioNav.calf' })}
              </p>
            </button>
            {!collapsed.calf && (
              <div className={cn('flex flex-col items-stretch gap-2 pl-2', className)}>
                {(['calf-results', 'calf-configuration'] as const).map((production) => (
                  <ScenarioNavigationMenuLink
                    key={`scenario-nav-${production}`}
                    active={active === production}
                    page={production}
                  />
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
)
