import { type FC } from 'react'

import { BalanceSheetView } from '@via/components'

import { useScenarioBalancePage } from './useScenarioBalancePage.ts'

export const ScenarioBalancePage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioBalancePage()
  return <BalanceSheetView key={props.scenario._id} snapshot={snapshot} {...props} />
}
