import '@fontsource/overpass'
import '@fontsource/overpass-mono'

import React from 'react'
import ReactDOM from 'react-dom/client'

import {
  browserTracingIntegration,
  feedbackIntegration,
  replayIntegration,
  tanstackRouterBrowserTracingIntegration,
  withProfiler,
} from '@sentry/react'
import { French } from '@via/components'
import { BigNumber } from 'bignumber.js'

import { initSentry } from './sentry/initSentry.ts'
import { App } from './App.tsx'
import { router } from './router.ts'

import '@fontsource/overpass/300.css'
import '@fontsource/overpass/500.css'
import '@fontsource/overpass/700.css'
import '@fontsource/overpass-mono/600.css'
import './index.css'

BigNumber.DEBUG = Boolean(import.meta.env.VITE_DEBUG_BIG_NUMBER)

initSentry([
  browserTracingIntegration(),
  replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  }),
  tanstackRouterBrowserTracingIntegration(router),
  feedbackIntegration({
    colorScheme: 'light',
    showBranding: false,
    id: import.meta.env.DEV ? 'sentry-feedback-dev' : undefined,
    useSentryUser: {
      name: 'displayName',
      email: 'email',
    },
    triggerLabel: '', // Removing the prop would default the label to 'Report a Bug'
    formTitle: French['widget.feedback.formTitle'],
    submitButtonLabel: French['widget.feedback.submitButtonLabel'],
    cancelButtonLabel: French['widget.feedback.cancelButtonLabel'],
    nameLabel: French['widget.feedback.nameLabel'],
    namePlaceholder: French['widget.feedback.namePlaceholder'],
    emailLabel: French['widget.feedback.emailLabel'],
    emailPlaceholder: French['widget.feedback.emailPlaceholder'],
    isRequiredLabel: French['widget.feedback.isRequiredLabel'],
    messageLabel: French['widget.feedback.messageLabel'],
    messagePlaceholder: French['widget.feedback.messagePlaceholder'],
    successMessageText: French['widget.feedback.successMessageText'],
    addScreenshotButtonLabel: French['widget.feedback.addScreenshotButtonLabel'],
    removeScreenshotButtonLabel: French['widget.feedback.removeScreenshotButtonLabel'],
  }),
])

// eslint-disable-next-line react-refresh/only-export-components
const SentryProfiledApp = withProfiler(App)

ReactDOM.createRoot(document.querySelector('#root')!).render(
  <React.StrictMode>{import.meta.env.MODE !== 'production' ? <SentryProfiledApp /> : <App />}</React.StrictMode>
)
