import { BigNumber } from 'bignumber.js'

import { createBudgetDefinition } from '../types'
import { isFermeBudgitel2021, isFermeBudgitel2022, sumBy } from '../utils'
import {
  CerealProductions,
  type ViagritelCourtTermeType,
  type ViagritelCourtTermeTypeLigne,
  type ViagritelProduction,
  type ViagritelTypeLigneCompteExploitation,
} from '../values'

const commercialProductions: ViagritelProduction[] = ['T1', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7', 'T8'] as const

export const company = createBudgetDefinition({
  'company.income.imported.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(49_554),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(105),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation, courtsTermes }) => {
        const ctLignes: Array<{
          type: ViagritelCourtTermeType | ViagritelCourtTermeType[]
          type_ligne: ViagritelCourtTermeTypeLigne
        }> = [
          { type: 'EP', type_ligne: 'EN' }, // G/P sur encaisse,                                                         ZZ17
          { type: 'EP', type_ligne: 'PL' }, // G/P sur placements court terme,                                           ZZ17
          { type: ['CR', 'CP', 'FP', 'FR'], type_ligne: 'ZZ' }, // GP sur C/R et P/A divers & G/P sur C/P et R/A divers,
          { type: 'CR', type_ligne: '20' }, // C/R G/P Agri-Stabilité,                                                   ZZ426
          { type: 'CR', type_ligne: '30' }, // C/R G/P ligne Agri(Qc&Invest),                                            ZZ464
          { type: 'CP', type_ligne: '10' }, // C/P G/P sur Marge de crédit,
          { type: 'CP', type_ligne: '20' }, // C/P G/P sur Paiements anticipés,
          { type: 'CP', type_ligne: '30' }, // C/P G/P sur Agri(Qc &Invest),
          { type: ['CR', 'FP'], type_ligne: 'GL' }, // G/P sur C/R Global maraîcher & Payé d'av. Global maraîcher
          { type: ['CP', 'FR'], type_ligne: 'GL' }, // G/P sur C/P Global marîcher & Reçu d'av. global maraicher
          { type: 'CS', type_ligne: 'CT' }, // G/P sur Agri(Qc & Invest)
        ]
        const generalCELignes = [
          170_400, // Ristournes,                              ZZ156
          170_500, // Rev. agricoles except.,                  ZZ157
          170_600, // Autres revenus divers,                   ZZ158
          170_700, // Rev. placements c.t.,                    ZZ159
          171_100, // Rev. location quotas,                    ZZ347
          171_000, // Rev.autres productions,                  ZZ326
          171_200, // Mnt reçu pour lisier / solide / liquide, ZZ637
          170_070, // Rev. d'interêts Agri (Qc & Invest.),     ZZ430
          170_800, // Revenu érable secondaire
          170_900, // Rev. boisé secondaire
        ]
        const terreEtBatimentsCELignes = [
          160_100, // Rev. location bâtiments, ZZ178
          160_200, // Rev. location terre,     ZZ179
        ]
        const courtTermeSum = ctLignes.reduce(
          (total, filter) => total.plus(sumBy(courtsTermes(filter), 'profit_perte')),
          new BigNumber(0)
        )
        const ceGeneralSum = sumBy(comptesExploitation({ production: 'GV', ligne: generalCELignes }), 'montant')
        const ceTerreEtBatimentsSum = sumBy(
          comptesExploitation({ production: 'GT', ligne: terreEtBatimentsCELignes }),
          'montant'
        )
        return courtTermeSum.plus(ceGeneralSum).plus(ceTerreEtBatimentsSum)
      },
    },
  },
  'company.income.imported.programs': {
    testResolver: ({ cri }) => cri('ZZ429').plus(cri('ZZ461')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        // Somme des  Revenus AgriQc & AgriInvest g  ($) et des revenus Agri Stabilité du compte général
        const ceLignes = [
          170_050, // Rev. Agri (Qc & Invest.), ZZ429
          170_550, // Rev. Agri-Stabilité,      ZZ461
        ]
        return sumBy(comptesExploitation({ production: 'GV', ligne: ceLignes }), 'montant')
      },
    },
  },
  'company.income.imported.custom': {
    testResolver: ({ cri }) =>
      cri('GC12')
        .plus(cri('GC13'))
        .plus(cri('GC15'))
        .plus(cri('GC17'))
        .plus(cri('GC19'))
        .plus(cri('GC21'))
        .plus(cri('GC23'))
        .plus(cri('GC24'))
        .plus(cri('GC25'))
        .plus(cri('GC27'))
        .plus(cri('GC29'))
        .plus(cri('GC31'))
        .plus(cri('GC32'))
        .plus(cri('GC33'))
        .plus(cri('GC34'))
        .plus(cri('GC35'))
        .plus(cri('ZZ638')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceMachinerieLignes = [
          140_100, // Rev. location tracteur,  GC12
          140_200, // Rev. loc. mach.& équip., GC13
          140_400, // Labour,                  GC15
          140_500, // Préparation sol,         GC17
          140_600, // Semis,                   GC19
          140_700, // Arrosage,                GC21
          140_800, // Battage,                 GC23
          140_900, // Épandage fumier,         GC24
          141_000, // Transport,               GC25
          141_100, // Séchage,                 GC27
          141_200, // Ensilage,                GC29
          141_300, // Pressage,                GC31
          141_400, // Ensachage de fourrage,   GC32
          141_500, // Déneigement,             GC33
          141_600, // Camionnage,              GC34
          141_700, // Autres et non détaillés, GC35
        ]
        const ceGeneralLignes = [
          171_300, // Revenus de transport lisier, ZZ638
        ]
        return sumBy(comptesExploitation({ production: 'GP', ligne: ceMachinerieLignes }), 'montant').plus(
          sumBy(comptesExploitation({ production: 'GV', ligne: ceGeneralLignes }), 'montant')
        )
      },
    },
  },
  'company.expenses.imported.fuel.tractors': {
    testResolver: 'ZZ199',
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceAmount = sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: 143_000, // Carburant tracteurs ZZ199
          }),
          'montant'
        )

        const iaAmount = sumBy(
          inventairesApprovisionnements({
            production: 'GP', // Machinerie
            ligne: 120_000, // Carburant pour tracteurs ZZ199
          }),
          'delta_dollars'
        )

        return ceAmount.plus(iaAmount)
      },
    },
  },
  'company.expenses.imported.fuel.trucks': {
    testResolver: 'ZZ201',
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceAmount = sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: 143_200, // Carburant camions ZZ201
          }),
          'montant'
        )

        const iaAmount = sumBy(
          inventairesApprovisionnements({
            production: 'GP', // Machinerie
            ligne: 120_200, // Carburant pour camions ZZ201
          }),
          'delta_dollars'
        )

        return ceAmount.plus(iaAmount)
      },
    },
  },
  'company.expenses.imported.fuel.thresher': {
    testResolver: 'ZZ200',
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceAmount = sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: 143_100, // Carburant batteuses ZZ200
          }),
          'montant'
        )

        const iaAmount = sumBy(
          inventairesApprovisionnements({
            production: 'GP', // Machinerie
            ligne: 120_100, // Carburant pour batteuses ZZ200
          }),
          'delta_dollars'
        )

        return ceAmount.plus(iaAmount)
      },
    },
  },
  'company.expenses.imported.fuel.miscellaneous': {
    testResolver: ({ cri }) => cri('ZZ198').plus(cri('ZZ202')).plus(cri('ZZ203')),
    databaseResolver: {
      resolve: ({ comptesExploitation, inventairesApprovisionnements }) => {
        const ceAmount = sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: [
              143_300, // Carburant non détaillé ZZ202
              143_400, // Propane&gaz séchage    ZZ203
            ],
          }),
          'montant'
        )

        const iaAmount = sumBy(
          inventairesApprovisionnements({
            production: 'GP', // Machinerie
            ligne: [
              120_300, // Carburant non détaillé ZZ202
              120_400, // Propane pour cultures  ZZ203
              120_410, // Huile et graisse       ZZ198
            ],
          }),
          'delta_dollars'
        )

        return ceAmount.plus(iaAmount)
      },
    },
  },
  'company.expenses.imported.machinery.tractors': {
    testResolver: ({ cri }) => cri('ZZ193').plus(cri('ZZ204')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          142_300, // Entretien tracteurs ZZ193
          143_600, // Location tracteur   ZZ204
        ]
        return sumBy(comptesExploitation({ production: 'GP', ligne: ceLignes }), 'montant')
      },
    },
  },
  'company.expenses.imported.machinery.trucks': {
    testResolver: 'ZZ197',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: 142_700, // Entr, enreg, ass. camions ZZ197
          }),
          'montant'
        ),
    },
  },
  'company.expenses.imported.machinery.thresher': {
    testResolver: 'ZZ195',
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(
          comptesExploitation({
            production: 'GP', // Machinerie
            ligne: 142_500, // Entretien batteuses ZZ195
          }),
          'montant'
        ),
    },
  },
  'company.expenses.imported.machinery.dryer': {
    testResolver: 'ZZ196',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GP', ligne: 142_600 }), 'montant'), // Entretien séchoirs ZZ196
    },
  },
  'company.expenses.imported.machinery.maintenance': {
    testResolver: ({ cri }) =>
      cri('IS21')
        .plus(cri('IS22'))
        .plus(cri('ZZ206'))
        .plus(cri('CR38'))

        .plus(cri('AX43'))
        .plus(cri('BS43'))
        .plus(cri('CV43'))
        .plus(cri('DG43'))
        .plus(cri('DL43'))
        .plus(cri('LP43'))
        .plus(cri('OC43'))
        .plus(cri('OI43'))
        .plus(cri('OT43'))
        .plus(cri('PG43'))
        .plus(cri('PS43'))
        .plus(cri('ST43'))
        .plus(cri('TA43'))
        .plus(cri('VL43'))
        .plus(cri('WA43'))

        .plus(cri('ZZ369'))
        .plus(cri('IN52'))
        .plus(cri('ZZ401'))
        .plus(cri('FI30'))
        .plus(cri('JV38'))
        .plus(cri('ZZ351'))
        .plus(cri('ZZ194'))
        .plus(cri('ZZ205'))
        .plus(cri('ZZ352')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'IS',
            ligne: [
              31_800, // Entretien mach. et équip. IS21
              31_900, // Loc  machinerie et équip. IS22
            ],
          },
          { type_ligne: 'MA', ligne: [133_700] }, // Entr.&loc. mach&équip.   ZZ206
          { type_ligne: 'CR', ligne: [190_260] }, // Entr. & loc. équipements CR38
          { type_ligne: 'AX', ligne: [64_100] }, // Entr. & loc. équipements  AX43
          { type_ligne: 'BI', ligne: [73_100] }, // Entr. & loc. Équipements  ZZ369
          { type_ligne: 'IN', ligne: [114_400] }, // Entr. & loc. équipements IN52
          { type_ligne: 'NA', ligne: [43_200] }, // Entr. & loc. équipements  ZZ401
          { type_ligne: 'FI', ligne: [53_300] }, // Entr. & loc. équipements  FI30
          { type_ligne: 'JV', ligne: [184_000] }, // Entr. & loc. équipements JV38
          {
            type_ligne: 'GP',
            ligne: [
              142_200, // Quincaillerie et outils ZZ351
              142_400, // Entretien machineries   ZZ194
              143_700, // Loc. mach.&équip. n-dét ZZ205
              143_800, // Frais mach. commun      ZZ352
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.machinery.miscellaneous': {
    testResolver: ({ cri }) =>
      commercialProductions
        .reduce((acc, production) => acc.plus(cri(`${production}18`).plus(cri(`${production}19`))), new BigNumber(0))
        .plus(cri('IS18'))
        .plus(cri('IS19'))
        .plus(cri('IS20'))
        .plus(cri('ZZ169'))
        .plus(cri('ZZ650'))
        .plus(cri('ZZ651'))
        .plus(cri('ZZ652'))
        .plus(cri('ZZ653')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'TX', // Divers commerce
            ligne: [
              151_500, // Entr.& carb. pour mach. spéc. CO18
              151_600, // Entr. équipements             CO19
            ],
          },
          {
            type_ligne: 'IS', // Boisé
            ligne: [
              31_500, // Scie mécanique (entr, carb) IS18
              31_600, // Entretien débusqueuse       IS19
              31_700, // Carburant débusqueuse       IS20
            ],
          },
          {
            type_ligne: 'GV', // Général
            ligne: [172_400], // Auto (entr., enreg.,etc) ZZ169
          },
          {
            type_ligne: 'GP', // Machinerie
            ligne: [
              142_225, // Entr. d'équip. fabr. moulée       ZZ650
              142_250, // Entr. d'équip. compostage         ZZ651
              142_275, // Entr. d'équip. d'incinération     ZZ652
              142_285, // Entr. de syst. traitement lisiers ZZ653
              142_800, // Entretien non détaillé            ZZ198
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.custom.threshing': {
    testResolver: ({ cri }) =>
      CerealProductions.reduce((acc, production) => acc.plus(cri(`${production}33`)), new BigNumber(0)).plus(
        cri('GC55')
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [102_700] }, // Battage forf. et loc. CE33
          { type_ligne: 'GP', ligne: [144_500] }, // Battage n-détaillé    GC55
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.custom.drying': {
    testResolver: ({ cri }) =>
      CerealProductions.reduce((acc, production) => acc.plus(cri(`${production}37`)), new BigNumber(0)).plus(
        cri('GC59')
      ),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'CE', ligne: [102_900] }, // Séchage forf. et loc. CE37
          { type_ligne: 'GP', ligne: [144_800] }, // Séchage n-détaillé    GC59
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.custom.fertilization': {
    testResolver: ({ cri }) =>
      cri('ZZ207')
        .plus(cri('ZZ623'))
        .plus(cri('ZZ624'))
        .plus(cri('ZZ625'))
        .plus(cri('ZZ627'))
        .plus(cri('ZZ628'))
        .plus(cri('ZZ629'))
        .plus(cri('ZZ630'))
        .plus(cri('ZZ632'))
        .plus(cri('ZZ633'))
        .plus(cri('ZZ634'))
        .plus(cri('ZZ635'))
        .plus(cri('ZZ636')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          144_600, // Epandage fumier ZZ207
          150_030, // Frais de pompe ZZ623
          150_040, // Frais tracteur ZZ624
          150_050, // Frais de main-d'oeuvre ZZ625
          150_070, // Frais de tracteur ZZ627
          150_080, // Frais de camion ZZ628
          150_090, // Frais de citerne et rampe d'épandage ZZ629
          150_095, // Frais de main-d'oeuvre ZZ630
          150_140, // Coût du traitement du lisier à forfait ZZ632
          150_150, // Coût de la disposition solide ZZ633
          150_160, // Coût de la disposition liquide ZZ634
          150_170, // Frais de disposition à l'extérieur ZZ635
          150_180, // Frais de transport ZZ636
        ]
        return sumBy(comptesExploitation({ production: 'GP', ligne: ceLignes }), 'montant')
      },
    },
  },
  'company.expenses.imported.custom.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(157_018),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(231_036),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'IS',
            ligne: [
              32_100, // Coupe et autres trav.  forf. IS24
              32_300, // Transport                    IS26
            ],
          },
          {
            type_ligne: 'MA',
            ligne: [
              132_900, // Forf. et loc. semis      GL33
              133_000, // Forf. et loc. arrosage   GL34
              133_100, // Forf. et loc. récolte    GL35
              133_200, // Forf. et loc. transport  GL36
              133_300, // Forf et loc. entreposage GL37
              133_400, // Forfait emballage        GL45
              133_500, // Autres forfaits et loc.  GL38
            ],
          },
          {
            type_ligne: 'CE',
            ligne: [
              102_500, // Semis forf. et loc.     CE30
              102_600, // Arrosage forf. et loc.  CE31
              102_800, // Pressage forf. et loc.  CE35
              103_000, // Transport forf. et loc. CE38
              103_100, // Entrep. forf. et loc.   CE39
              103_200, // Autres forfaits et loc. CE40
            ],
          },
          {
            type_ligne: 'FO',
            ligne: [
              82_100, // Semis forf. et loc.     FO30
              82_200, // Arrosage forf. et loc.  FO31
              82_300, // Ensilage forf. et loc.  FO34
              82_400, // Pressage forf. et loc.  FO35
              82_500, // Autres forfaits et loc. FO40
            ],
          },
          {
            type_ligne: 'GP',
            ligne: [
              144_100, // Labour                  GC48
              144_200, // Préparation sol         GC49
              144_300, // Semis n-détaillé        GC50
              144_400, // Arrosage n-détaillé     GC52
              144_700, // Transport n-détaillé    GC67
              144_900, // Ensilage n-détaillé     GC61
              145_000, // Pressage n-détaillé     GC63
              145_050, // Déneigement             Pas de cri
              145_100, // Autres et non détaillés GC65
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.finance.short.interests': {
    testResolver: 'ZZ164',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 171_900 }), 'montant'), // Intérêts sur court terme ZZ164
    },
  },
  'company.expenses.imported.fixed.land.mechanical': {
    testResolver: ({ cri }) => cri('IS30').plus(cri('ZZ180')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'IS', ligne: [32_700] }, // Trav. méc., drainage IS30
          { type_ligne: 'GT', ligne: [160_700] }, // Travaux mécanisés   ZZ180
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.fixed.land.maintenance': {
    testResolver: ({ cri }) => cri('IS28').plus(cri('IS29')).plus(cri('IS31')).plus(cri('ZZ181')).plus(cri('ZZ182')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          {
            type_ligne: 'IS',
            ligne: [
              32_500, // Reboisement IS28
              32_600, // Entr. boisé (sylviculture) IS29
              32_800, // Entretien des chemins IS31
            ],
          },
          {
            type_ligne: 'GT',
            ligne: [
              160_800, // Entretien fonds de terre ZZ181
              160_900, // Entretien cour et clôtures ZZ182
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.fixed.land.lease': {
    testResolver: 'ZZ184',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GT', ligne: 161_200 }), 'montant'), // Location de terre ZZ184
    },
  },
  'company.expenses.imported.fixed.buildings.maintenance': {
    testResolver: ({ cri }) =>
      cri('LA33')
        .plus(cri('DA28'))
        .plus(commercialProductions.reduce((acc, production) => acc.plus(cri(`${production}20`)), new BigNumber(0)))
        .plus(cri('GL39')) // NOTE:les CRI maraîchers semblent avoir un bogue. Voir VARAPPC dans PluginLignes
        .plus(cri('CR37'))
        .plus(cri('AX42'))
        .plus(cri('BS42'))
        .plus(cri('CV42'))
        .plus(cri('DG42'))
        .plus(cri('DL42'))
        .plus(cri('LP42'))
        .plus(cri('OC42'))
        .plus(cri('OI42'))
        .plus(cri('OT42'))
        .plus(cri('PG42'))
        .plus(cri('PS42'))
        .plus(cri('ST42'))
        .plus(cri('TA42'))
        .plus(cri('VL42'))
        .plus(cri('WA42'))
        .plus(cri('BI31'))
        .plus(cri('IN51'))
        .plus(cri('NA28'))
        .plus(cri('FI29'))
        .plus(cri('JV37'))
        .plus(cri('ZZ647'))
        .plus(cri('ZZ648'))
        .plus(cri('ZZ649'))
        .plus(cri('ZZ187')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'LA', ligne: [23_600] }, // Entr. & loc. bâtiments   LA33
          { type_ligne: 'DA', ligne: [3500] }, // Entretien des bâtiments    DA28
          { type_ligne: 'TX', ligne: [151_700] }, // Entr. bâtiments         CO20
          { type_ligne: 'MA', ligne: [133_600] }, // Entr&loc. bât. spéc.    GL39
          { type_ligne: 'CR', ligne: [190_250] }, // Entr. & loc. bâtiments  CR37
          { type_ligne: 'AX', ligne: [63_900] }, // Entr. & loc. bâtiments   AX42
          { type_ligne: 'BI', ligne: [72_900] }, // Entr. & loc. Bâtiments   BI31
          { type_ligne: 'IN', ligne: [114_200] }, // Entr. & loc. bâtiments  IN51
          { type_ligne: 'NA', ligne: [43_000] }, // Entr. & loc. bâtiments   NA28
          { type_ligne: 'FI', ligne: [53_100] }, // Entr. & loc. bâtiments   FI29
          { type_ligne: 'JV', ligne: [183_800] }, // Entr. et loc. bâtiments JV37
          { type_ligne: 'VA', ligne: [93_900] }, // Entr. & loc. bâtiments   VA120
          {
            type_ligne: 'GT',
            ligne: [
              160_825, // Entr. bât. fabrication de moulée ZZ647
              160_850, // Entr. bât. de compostage         ZZ648
              160_875, // Entr. bât. d'incinération        ZZ649
              161_400, // Entr. bât.  non détaillé         ZZ187
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.fixed.buildings.lease': {
    testResolver: 'ZZ189',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GT', ligne: 161_500 }), 'montant'), // Location bâtiments ZZ189
    },
  },
  'company.expenses.imported.fixed.buildings.miscellaneous': {
    testResolver: ({ cri }) => cri('ZZ208').plus(cri('ZZ350')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          161_600, // Entreposage n-détaillé    ZZ208
          161_700, // Chauffage bât. n-détaillé ZZ350
        ]
        return sumBy(comptesExploitation({ production: 'GT', ligne: ceLignes }), 'montant')
      },
    },
  },
  'company.expenses.imported.fixed.salaries.permanent': {
    testResolver: ({ cri }) =>
      cri('DA29')
        .plus(commercialProductions.reduce((acc, production) => acc.plus(cri(`${production}17`)), new BigNumber(0)))

        .plus(cri('IS25'))
        .plus(cri('ZZ160'))

        .plus(cri('CJ85'))
        .plus(cri('CJ83'))
        .plus(cri('CJ81'))
        .plus(cri('CJ87'))
        .plus(cri('CJ77'))
        .plus(cri('CJ75'))
        .plus(cri('CJ73'))
        .plus(cri('CJ79'))
        .plus(cri('CJ61'))
        .plus(cri('CJ59'))
        .plus(cri('CJ57'))
        .plus(cri('CJ63'))
        .plus(cri('CJ69'))
        .plus(cri('CJ67'))
        .plus(cri('CJ65'))
        .plus(cri('CJ71'))
        .plus(cri('CJ107'))

        .plus(cri('GQ66'))
        .plus(cri('GQ68'))
        .plus(cri('GQ70'))
        .plus(cri('GQ72'))
        .plus(cri('GQ74'))
        .plus(cri('GQ76'))
        .plus(cri('GQ78'))
        .plus(cri('GQ80'))
        .plus(cri('GQ82'))
        .plus(cri('GQ84'))
        .plus(cri('GQ86'))
        .plus(cri('GQ88'))
        .plus(cri('GQ90'))
        .plus(cri('GQ92'))
        .plus(cri('GQ94'))
        .plus(cri('GQ96'))
        .plus(cri('GQ143'))

        .plus(cri('GS64'))
        .plus(cri('GS66'))
        .plus(cri('GS68'))
        .plus(cri('GS70'))
        .plus(cri('GS72'))
        .plus(cri('GS74'))
        .plus(cri('GS76'))
        .plus(cri('GS78'))
        .plus(cri('GS80'))
        .plus(cri('GS82'))
        .plus(cri('GS84'))
        .plus(cri('GS86'))
        .plus(cri('GS88'))
        .plus(cri('GS90'))
        .plus(cri('GS92'))
        .plus(cri('GS94'))
        .plus(cri('GS136'))

        .plus(cri('GL41'))
        .plus(cri('GL42'))
        .plus(cri('GL43'))
        .plus(cri('GL44')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{
          type_ligne: ViagritelTypeLigneCompteExploitation | ViagritelTypeLigneCompteExploitation[]
          ligne: number[]
        }> = [
          { type_ligne: 'DA', ligne: [3600] }, // Salaires directs DA29
          { type_ligne: 'TX', ligne: [151_400] }, // Salaires directs CO17
          { type_ligne: 'IS', ligne: [32_200] }, // Salaires directs IS25
          { type_ligne: 'GV', ligne: [171_600] }, // Sal. employés permanents ZZ160
          {
            type_ligne: 'CJ',
            ligne: [
              14_400, // Main d'oeuvre général à forfait       CJ85
              14_300, // Main d'oeuvre général employés        CJ83
              14_200, // Main d'oeuvre général famille         CJ81
              14_450, // Main d'oeuvre général propriétaire    CJ87
              14_100, // Main d'oeuvre livraison à forfait     CJ77
              14_000, // Main d'oeuvre livraison employés      CJ75
              13_900, // Main d'oeuvre livraison famille       CJ73
              14_150, // Main d'oeuvre livraison propriétaire  CJ79
              13_500, // Main d'oeuvre production à forfait    CJ61
              13_400, // Main d'oeuvre production employés     CJ59
              13_300, // Main d'oeuvre production famille      CJ57
              13_550, // Main d'oeuvre production propriétaire CJ63
              13_800, // Main d'oeuvre ventes à forfait        CJ69
              13_700, // Main d'oeuvre ventes employés         CJ67
              13_600, // Main d'oeuvre ventes famille          CJ65
              13_850, // Main d'oeuvre ventes propriétaire     CJ71
              16_200, // Avantages sociaux non-répartis        CJ107
            ],
          },
          {
            type_ligne: 'PO',
            ligne: [
              204_000, // Main d'oeuvre production famille,      GQ66
              204_100, // Main d'oeuvre production employés,     GQ68
              204_200, // Main d'oeuvre production à forfait,    GQ70
              204_250, // Main d'oeuvre production propriétaire, GQ72
              204_300, // Main d'oeuvre ventes famille,          GQ74
              204_400, // Main d'oeuvre ventes employés,         GQ76
              204_500, // Main d'oeuvre ventes à forfait,        GQ78
              204_550, // Main d'oeuvre ventes propriétaire,     GQ80
              204_600, // Main d'oeuvre livraison famille,       GQ82
              204_700, // Main d'oeuvre livraison employés,      GQ84
              204_800, // Main d'oeuvre livraison à forfait,     GQ86
              204_850, // Main d'oeuvre livraison propriétaire,  GQ88
              204_900, // Main d'oeuvre général famille,         GQ90
              205_000, // Main d'oeuvre général employés,        GQ92
              205_100, // Main d'oeuvre général à forfait,       GQ94
              205_150, // Main d'oeuvre général propriétaire,    GQ96
              208_500, // Avantages sociaux non-répartis,        GQ143
            ],
          },
          {
            type_ligne: 'SO',
            ligne: [
              214_100, // Main d'oeuvre production famille,      GS64
              214_200, // Main d'oeuvre production employés,     GS66
              214_300, // Main d'oeuvre production à forfait,    GS68
              214_350, // Main d'oeuvre production propriétaire, GS70
              214_400, // Main d'oeuvre ventes famille,          GS72
              214_500, // Main d'oeuvre ventes employés,         GS74
              214_600, // Main d'oeuvre ventes à forfait,        GS76
              214_650, // Main d'oeuvre ventes propriétaire,     GS78
              214_700, // Main d'oeuvre livraison famille,       GS80
              214_800, // Main d'oeuvre livraison employés,      GS82
              214_900, // Main d'oeuvre livraison à forfait,     GS84
              214_950, // Main d'oeuvre livraison propriétaire,  GS86
              215_000, // Main d'oeuvre général famille,         GS88
              215_100, // Main d'oeuvre général employés,        GS90
              215_200, // Main d'oeuvre général à forfait,       GS92
              215_250, // Main d'oeuvre général propriétaire,    GS94
              218_200, // Avantages sociaux non-répartis         GS136
            ],
          },
          {
            type_ligne: 'MA',
            ligne: [
              133_800, // Salaires culture        GL41
              133_900, // Salaires récolte        GL42
              134_000, // Salaires emballage      GL43
              134_100, // Salaires mise en marché GL44
            ],
          },
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.fixed.salaries.seasonal': {
    testResolver: 'ZZ161',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 171_700 }), 'montant'), // Salaires occasionnels ZZ161
    },
  },
  'company.expenses.imported.fixed.salaries.grants': {
    testResolver: 'ZZ152',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 170_300 }), 'montant'), // Subv. main-d'oeuvre ZZ152
    },
  },
  'company.expenses.imported.fixed.general.power': {
    testResolver: ({ cri }) => cri('DA31').plus(cri('ZZ167')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'DA', ligne: [3800] }, // Electricité érablière                  DA31
          { type_ligne: 'GV', ligne: [172_200] }, // Electricité & chauffage de bâtiment ZZ167
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.imported.fixed.general.communications': {
    testResolver: 'ZZ168',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 172_300 }), 'montant'), // Téléphone ZZ168
    },
  },
  'company.expenses.imported.fixed.general.taxes': {
    testResolver: ({ cri }) => cri('ZZ170').minus(cri('ZZ149')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) =>
        sumBy(comptesExploitation({ production: 'GV', ligne: 172_500 }), 'montant') // Taxes mun. & scol. ZZ170
          .minus(sumBy(comptesExploitation({ production: 'GV', ligne: 170_100 }), 'montant')), // Remb. taxes foncières ZZ149
    },
  },
  'company.expenses.imported.fixed.general.insurance': {
    testResolver: ({ cri }) => cri('IS32').plus(cri('ZZ171')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes: Array<{ type_ligne: ViagritelTypeLigneCompteExploitation; ligne: number[] }> = [
          { type_ligne: 'IS', ligne: [32_900] }, // Assurances directes,   IS32
          { type_ligne: 'GV', ligne: [172_600] }, // Assurances générales, ZZ171
        ]
        return ceLignes.reduce(
          (total, filter) => total.plus(sumBy(comptesExploitation(filter), 'montant')),
          new BigNumber(0)
        )
      },
    },
  },
  'company.expenses.fixed.general.imported.capital.taxes': {
    testResolver: 'ZZ174', // Taxe sur le capital
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 172_900 }), 'montant'),
    },
  },
  'company.expenses.imported.fixed.general.administration': {
    testResolver: ({ cri }) => cri('ZZ656').plus(cri('ZZ172')).plus(cri('ZZ174')),
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          172_650, // Cotisation agri-stabilité, ZZ656
          172_700, // Administration,            ZZ172
          172_900, // Taxe sur le capital,       ZZ174
        ]
        return sumBy(comptesExploitation({ production: 'GV', ligne: ceLignes }), 'montant')
      },
    },
  },
  'company.expenses.imported.fixed.general.professionals': {
    testResolver: 'ZZ173',
    databaseResolver: {
      resolve: ({ comptesExploitation }) => sumBy(comptesExploitation({ production: 'GV', ligne: 172_800 }), 'montant'), // Cot., honor. profess. ZZ173
    },
  },
  'company.expenses.imported.fixed.general.miscellaneous': {
    testResolver: [
      {
        condition: isFermeBudgitel2021,
        expectedValue: new BigNumber(30_108),
      },
      {
        condition: isFermeBudgitel2022,
        expectedValue: new BigNumber(28_908),
      },
    ],
    databaseResolver: {
      resolve: ({ comptesExploitation }) => {
        const ceLignes = [
          173_000, // Dép. adm. except.,                            ZZ175
          173_100, // Dép. gén. diverses,                           ZZ176
          173_200, // Dép. érable secondaire,                       pas de CRI
          173_300, // Dép. boisé secondaire,                        pas de CRI
          173_400, // Dép. autres productions,                      ZZ327
          173_600, // Frais environnementaux n-détaillés,           ZZ639
          173_620, // Coût d'analyse de sol,                        ZZ641
          173_630, // Coût d'analyse du lisier ( nb échantillons ), ZZ642
          173_640, // Coût bilan alimentaire,                       ZZ643
          173_650, // Coût bilan phosphore,                         ZZ644
          173_660, // Coût élaboration des PAEF,                    ZZ645
          173_670, // Frais notariés,                               ZZ646
        ]
        return sumBy(comptesExploitation({ production: 'GV', ligne: ceLignes }), 'montant')
      },
    },
  },
})
