import { createFileRoute } from '@tanstack/react-router'

import { MilkResultsPage } from '../pages/milkResults/MilkResultsPage.tsx'

const MilkResultsComponent = () => <MilkResultsPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
)({
  component: MilkResultsComponent,
})
