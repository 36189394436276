import { createFileRoute } from '@tanstack/react-router'

import { MilkAnimalsPage } from '../pages/milkAnimals/MilkAnimalsPage.tsx'

const MilkAnimalsComponent = () => <MilkAnimalsPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
)({
  component: MilkAnimalsComponent,
})
