import { type DataKey } from '../../data-key-zod'
import { type DataAttribute } from '../types'

export const BalanceTableAttributes = {
  'balance.assets.short': {
    type: 'total',
    readonly: true,
    dependencies: [
      'balance.assets.short.liquidities',
      'balance.assets.short.programs',
      'balance.assets.short.receivables',
      'balance.assets.short.inventory',
    ],
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.liquidities': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.liquidities.balance'],
    arithmetic: { minValue: 0 },
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.programs': {
    type: 'alias',
    key: 'finance.short.assets.programs.balance',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.receivables': {
    type: 'alias',
    key: 'finance.short.assets.receivables.balance',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory': {
    type: 'total',
    readonly: true,
    dependencies: [
      'balance.assets.short.inventory.forage.amount',
      'balance.assets.short.inventory.cereals.amount',
      'balance.assets.short.inventory.slaughter.amount',
      'balance.assets.short.inventory.supplies.amount',
      'balance.assets.short.inventory.miscellaneous.amount',
    ],
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory.forage.amount': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.short.inventory.forage.balance',
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory.cereals.amount': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.short.inventory.cereals.balance',
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory.slaughter.amount': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.short.inventory.slaughter.balance',
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory.supplies.amount': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.inventory.supplies.amount',
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.short.inventory.miscellaneous.amount': {
    type: 'alias',
    readonly: true,
    key: 'finance.asset.short.inventory.miscellaneous.balance',
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long': {
    type: 'total',
    readonly: true,
    dependencies: [
      'balance.assets.long.breeders.amount',
      'balance.assets.long.machinery.amount',
      'balance.assets.long.buildings.amount',
      'balance.assets.long.land.amount',
      'balance.assets.long.quotas.amount',
      'balance.assets.long.nonfarming.amount',
    ],
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.breeders.amount': {
    type: 'alias',
    key: 'finance.asset.inventory.breeders.balance',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.machinery.amount': {
    type: 'alias',
    key: 'finance.asset.machinery.total.value',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.buildings.amount': {
    type: 'alias',
    key: 'finance.asset.building.total.contributory-value',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.land.amount': {
    type: 'alias',
    key: 'finance.asset.land.total.value',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.quotas.amount': {
    type: 'alias',
    key: 'finance.asset.quota.total.value',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.long.nonfarming.amount': {
    type: 'alias',
    key: 'finance.asset.other.agricultural.total.value',
    readonly: false,
    fractionOf: 'balance.assets.total',
  },
  'balance.assets.total': {
    type: 'total',
    readonly: true,
    dependencies: ['balance.assets.short', 'balance.assets.long'],
    fractionOf: 'balance.assets.total',
  },

  'balance.liabilities.short': {
    type: 'total',
    readonly: true,
    dependencies: [
      'balance.liabilities.short.banking',
      'balance.liabilities.short.credit',
      'balance.liabilities.short.payables',
      'balance.liabilities.short.loans',
    ],
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.short.banking': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.short.liquidities.balance'],
    arithmetic: { minValue: 0, 'finance.short.liquidities.balance': { multiplier: -1 } },
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.short.credit': {
    type: 'alias',
    key: 'finance.short.liabilities.credit.balance',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.short.payables': {
    type: 'alias',
    key: 'finance.short.liabilities.payables.balance',
    readonly: true,
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.short.loans': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.loan.agricultural.medium.total.exigible-capital',
      'finance.loan.agricultural.long.total.exigible-capital',
    ],
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.loans': {
    type: 'total',
    readonly: true,
    dependencies: ['balance.liabilities.loans.medium', 'balance.liabilities.loans.long'],
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.loans.medium': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.loan.agricultural.medium.total.remaining-capital',
      'finance.loan.agricultural.medium.total.exigible-capital',
    ],
    arithmetic: {
      'finance.loan.agricultural.medium.total.exigible-capital': { multiplier: -1 },
    },
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.loans.long': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.loan.agricultural.long.total.remaining-capital',
      'finance.loan.agricultural.long.total.exigible-capital',
    ],
    arithmetic: {
      'finance.loan.agricultural.long.total.exigible-capital': { multiplier: -1 },
    },
    fractionOf: 'balance.assets.total',
  },
  'balance.liabilities.total': {
    type: 'total',
    readonly: true,
    dependencies: ['balance.liabilities.short', 'balance.liabilities.loans'],
    fractionOf: 'balance.assets.total',
  },
  'balance.net.total': {
    type: 'total',
    readonly: true,
    dependencies: ['balance.assets.total', 'balance.liabilities.total'],
    arithmetic: { 'balance.liabilities.total': { multiplier: -1 } },
    fractionOf: 'balance.assets.total',
  },
  'balance.validation': {
    type: 'total',
    readonly: true,
    dependencies: [
      'balance.validation.profit',
      'balance.validation.inflation',
      'balance.validation.withdrawals',
      'balance.validation.contributions',
    ],
    arithmetic: {
      'balance.validation.withdrawals': { multiplier: -1 },
    },
  },
  'balance.validation.profit': { type: 'alias', readonly: true, key: 'result.profit' },
  'balance.validation.inflation': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.asset.animal.breeders.total.inflation',
      'finance.asset.forage.total.inflation',
      'finance.asset.total.inflation',
    ],
  },
  'balance.validation.withdrawals': {
    type: 'total',
    readonly: true,
    dependencies: ['repayment.charges.withdrawals.total', 'repayment.charges.tax'],
  },
  'balance.validation.contributions': { type: 'alias', readonly: true, key: 'repayment.inflows.contributions' },
  'balance.validation.other-movements': {
    type: 'total',
    readonly: true,
    dependencies: [
      'finance.asset.total.value-balance-movement',
      'outflows.investment.personal',
      'outflows.investment.rrsp',
      'outflows.investment.non-agricultural',
      'inflows.investment.shares',
    ],
    arithmetic: {
      'outflows.investment.personal': { multiplier: -1 },
      'outflows.investment.rrsp': { multiplier: -1 },
      'outflows.investment.non-agricultural': { multiplier: -1 },
    },
  },
  'balance.owners.assets.total': { type: 'alias', readonly: true, key: 'balance.assets.total' },
  'balance.exclusions.residence.amount': {
    type: 'alias',
    key: 'finance.asset.other.residency.total.value',
    readonly: false,
  },
  'balance.exclusions.nonfarming.assets.amount': {
    type: 'alias',
    key: 'finance.asset.other.non-agricultural.total.value',
    readonly: false,
  },
  'balance.exclusions.buildings.loss.amount': {
    type: 'total',
    readonly: true,
    dependencies: ['finance.asset.building.total.value', 'finance.asset.building.total.contributory-value'],
    arithmetic: {
      'finance.asset.building.total.contributory-value': { multiplier: -1 },
    },
  },
} as const satisfies Partial<Record<DataKey, DataAttribute>>
