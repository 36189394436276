import { useCallback, useContext } from 'react'

import { isDataKey } from '@via/schema'
import { type Except } from 'type-fest'

import { InvestmentOperationsContext } from '../../context'
import { type MonetaryRowData } from '../../model'
import { InvestmentDetail } from '../InvestmentDetail/InvestmentDetail.tsx'

import { MonetaryReadonlyValueCell, type MonetaryReadonlyValueCellProps } from './MonetaryReadonlyValueCell'
import { SheetEditableValueCell } from './SheetEditableValueCell'

export interface InvestmentDetailValueCellProps extends Except<MonetaryReadonlyValueCellProps, 'children'> {
  readonly rowData: MonetaryRowData
  readonly type: 'finance-investment' | 'finance-loan-investment' | 'finance-investment-inventory'
  readonly disabled?: boolean
}

export const InvestmentDetailValueCell = ({
  rowData,
  cellData,
  type,
  disabled,
  ...props
}: InvestmentDetailValueCellProps) => {
  const { onDeleteInvestment, onUpdateInvestment } = useContext(InvestmentOperationsContext)
  const { stateDataId, id, key } = rowData
  const onDelete = useCallback(
    async (closeSheet: () => void) => {
      await onDeleteInvestment(stateDataId ?? id, closeSheet)
    },
    [stateDataId, id, onDeleteInvestment]
  )

  if (!isDataKey(key)) {
    return <MonetaryReadonlyValueCell cellData={cellData} {...props} />
  }

  return (
    <SheetEditableValueCell cellData={cellData} {...props}>
      {({ onClose }) => (
        <InvestmentDetail
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          investmentId={stateDataId!}
          dataKey={key}
          computationType={type}
          onSubmit={async (value) => {
            await onUpdateInvestment(value)
            onClose()
          }}
          disabled={disabled}
          onApply={(value) => onUpdateInvestment(value)}
          onDelete={() => onDelete(onClose)}
        />
      )}
    </SheetEditableValueCell>
  )
}
