import { useMemo } from 'react'

import { useLocalStorageValue } from '@react-hookz/web/useLocalStorageValue/index.js'
import { type ScenarioNavigationProps } from '@via/components'

type ScenarioNavigationLocalState = Pick<ScenarioNavigationProps, 'navigationMenuCollapsedState'>

export const useScenarioNavigationLocalState = () => {
  const { value, set } = useLocalStorageValue<ScenarioNavigationLocalState['navigationMenuCollapsedState']>(
    `via-scenario-navigation`,
    { defaultValue: {} }
  )

  return useMemo<Pick<ScenarioNavigationProps, 'navigationMenuCollapsedState' | 'onNavigationMenuCollapse'>>(
    () => ({
      navigationMenuCollapsedState: value ?? {},
      onNavigationMenuCollapse: (state) => set(state),
    }),
    [value, set]
  )
}
