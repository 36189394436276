import { createFileRoute } from '@tanstack/react-router'

import { CropsResultsPage } from '../pages/cropsResults/CropsResultsPage.tsx'

const CropsResultsComponent = () => <CropsResultsPage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
)({
  component: CropsResultsComponent,
})
