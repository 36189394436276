import { createContext } from 'react'

export interface MonetaryTableCollapseStateContextValue {
  isSectionOpen: (id: string) => boolean
  isOpen: (id: string) => boolean
}

export const MonetaryTableCollapseStateContext = createContext<MonetaryTableCollapseStateContextValue>({
  isSectionOpen: () => true,
  isOpen: () => true,
})
