import { useMemo } from 'react'
import { useIntl } from 'react-intl'

import { type DataKey } from '@via/schema'
import { mapValues } from 'lodash-es'

import { type BlueprintUdpTable } from '../blueprints/blueprint'
import { type MonetaryUdpTableBlueprint } from '../organisms/ScenarioMonetaryUdpTable'

export const useMonetaryTableUdpBlueprint = <K extends string, TUdpKey extends DataKey, TableStates extends string>(
  blueprint: BlueprintUdpTable<Record<K, TUdpKey>, TableStates>
) => {
  const intl = useIntl()

  return useMemo<MonetaryUdpTableBlueprint<K, TUdpKey, TableStates>>(
    () => ({
      ...blueprint,
      udpName: mapValues(blueprint.udp, (key) => intl.formatMessage({ id: `table.${key}` })),
    }),
    [intl, blueprint]
  )
}
