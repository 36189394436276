import { type BudgetData } from '@via/components'
import { addSentryBreadcrumb } from '@via/frontend-sentry'
import { concat, concatMap, EMPTY, filter, first, from, mergeMap, tap, toArray } from 'rxjs'

import { querySnapshotLocalDocumentData$ } from '../../rxdb/localSnapshot/querySnapshotLocalDocumentData.ts'
import { type AppRxDatabase } from '../../rxdb/types.ts'
import { deepDateToString } from '../../rxdb/utils/deepDateToString.ts'
import { rxDocumentData } from '../../rxdb/utils/rxDocumentData.ts'

export const pullBudgets$ = (appDatabase: AppRxDatabase) =>
  querySnapshotLocalDocumentData$<BudgetData>(appDatabase, 'budgets').pipe(
    tap(({ epochTimestamp }) => {
      if (import.meta.env.DEV) {
        performance.mark(`pull-budgets:${epochTimestamp}:start`)
      }
    }),
    concatMap(({ data: snapshotData, epochTimestamp }) =>
      appDatabase.budgets.find().$.pipe(
        first(),
        addSentryBreadcrumb((rxBudgets) => ({
          category: 'pull budgets',
          level: 'debug',
          type: 'debug',
          message: 'start pull budgets',
          data: {
            rxBudgets,
            snapshotData,
            epochTimestamp,
          },
        })),
        mergeMap((rxBudgets) =>
          concat(
            from(rxBudgets).pipe(
              filter((rxBudget) => !rxBudget.mustSync),
              addSentryBreadcrumb((rxBudget) => ({
                category: 'firestore',
                type: 'debug',
                level: 'debug',
                message: `pull budget (/budgets/${rxBudget._id}) (update budget)`,
                data: {
                  rxBudget,
                  snapshotData,
                  epochTimestamp,
                },
              })),
              mergeMap(async (rxBudget) => {
                const budgetId = rxBudget._id
                const budgetData = snapshotData.find((b) => b._id === budgetId)
                if (!budgetData) {
                  await rxBudget.incrementalRemove()
                  return EMPTY
                }
                const updatedDoc = await rxBudget.incrementalPatch({
                  // owner must be undefined  to remove it from the document
                  ...deepDateToString({ ...budgetData, owner: budgetData.owner ?? undefined }),
                  snapshotEpochTimestamp: epochTimestamp,
                  hasSyncError: false,
                })
                return rxDocumentData(updatedDoc)
              })
            ),
            from(snapshotData).pipe(
              filter((snapshotBudget) => !!snapshotBudget.currentUserIsOwner),
              filter((snapshotBudget) => !rxBudgets.some((rxBudget) => rxBudget._id === snapshotBudget._id)),
              addSentryBreadcrumb((snapshotBudget) => ({
                category: 'firestore',
                type: 'debug',
                level: 'debug',
                message: `pull budget (/budgets/${snapshotBudget._id}) (insert open budget)`,
                data: {
                  snapshotBudget,
                },
              })),
              mergeMap(async (budgetData) => {
                const rxDocument = await appDatabase.budgets.incrementalUpsert({
                  // owner must be undefined  to remove it from the document
                  ...deepDateToString({ ...budgetData, owner: budgetData.owner ?? undefined }),
                  snapshotEpochTimestamp: epochTimestamp,
                  hasSyncError: false,
                })
                return rxDocumentData(rxDocument)
              })
            )
          ).pipe(
            toArray(),
            addSentryBreadcrumb((updatedBudgets) => ({
              category: 'pull budgets',
              type: 'debug',
              level: 'debug',
              message: 'completed pull budgets',
              data: {
                updatedBudgets,
                snapshotData,
                epochTimestamp,
              },
            })),
            tap(() => {
              if (import.meta.env.DEV) {
                performance.mark(`pull-budgets:${epochTimestamp}:end`)
                const measure = performance.measure(
                  `pull-budgets:${epochTimestamp}`,
                  `pull-budgets:${epochTimestamp}:start`,
                  `pull-budgets:${epochTimestamp}:end`
                )
                // eslint-disable-next-line no-console
                console.debug('pull budgets measure', measure)
              }
            })
          )
        )
      )
    )
  )
