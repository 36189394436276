import { ReferenceAttributesJson } from '../../generated'

export interface ReferenceDataAttribute {
  readonly type: 'reference'
  readonly readonly: true
  readonly previousKeyNames?: ReadonlyArray<string>
}

export const ReferenceDataKeyAttributes = {
  ...(ReferenceAttributesJson as Record<keyof typeof ReferenceAttributesJson, ReferenceDataAttribute>),
}

export type ReferenceDataKey = keyof typeof ReferenceDataKeyAttributes
