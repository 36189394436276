import { forwardRef, type HtmlHTMLAttributes } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '../../lib/utils'

import { LineCollapsable } from './LineCollapsable'
import { LineFormulaStrip, type LineFormulaStripProps } from './LineFormulaStrip'
import { LineStrip, type LineStripProps } from './LineStrip'
import { LineTab, type LineTabProps } from './LineTab'
import { lineTitleConfiguration } from './lineTitleConfigurations'
import { type LineVariant } from './LineVariant'

export type LineTitleVariant = Exclude<LineVariant, 'divider'>

export interface LineTitleConfiguration {
  formulaStrip: LineFormulaStripProps['variant']
  strip: {
    variant: LineStripProps['variant']
    configuration: LineStripProps['configuration']
    filled: LineStripProps['filled']
  }
  externalTab?: LineTabProps['variant']
  tab?: LineTabProps['variant']
  collapsable: boolean
}

const lineTitleVariants = cva('flex h-6 w-84 shrink-0 truncate ', {
  variants: {
    variant: {
      default: '',
      section: '',
      subSectionProduction: '',
      subSection: '',
      subTotal: '',
      total: '',
      head: 'h-7',
      calculate: '',
      calculateDerived: '',
      calculateSubTotal: '',
      calculateTotal: 'h-7',
      finalSubTotal: 'h-7',
      finalTotal: 'h-7',
      validateAlert: '',
      subValidateAlert: '',
      validatePass: '',
      subValidatePass: '',
      group: '',
      groupCalculate: '',
      groupReference: '',
      basic: '',
      udp: 'h-7',
    } satisfies Record<LineTitleVariant, string>,
    withDividerRight: {
      true: 'border-r border-r-gray-400',
      false: '',
    },
  },
  defaultVariants: {
    variant: 'default',
    withDividerRight: false,
  },
})

const lineLabelVariants = cva('flex leading-6 w-full items-baseline gap-1.5 pl-1.5 pr-0.5', {
  variants: {
    variant: {
      default: '',
      section: '',
      subSectionProduction: '',
      subSection: '',
      subTotal: '',
      total: '',
      head: 'leading-7',
      calculate: '',
      calculateDerived: '',
      calculateSubTotal: '',
      calculateTotal: 'leading-7',
      finalSubTotal: 'leading-7',
      finalTotal: 'leading-7',
      validateAlert: '',
      subValidateAlert: '',
      validatePass: '',
      subValidatePass: '',
      group: '',
      groupCalculate: '',
      groupReference: '',
      basic: '',
      udp: 'leading-7',
    } satisfies Record<LineTitleVariant, string>,
  },
  defaultVariants: {
    variant: 'default',
  },
})

const lineTitleContainerVariants = cva('flex leading-6 w-full text-black truncate', {
  variants: {
    variant: {
      default: 'bg-white border-b border-b-gray-200',
      section: 'bg-orange-50 border-b border-b-gray-200 font-bold',
      subSectionProduction: 'bg-gray-200 border-b border-b-gray-200',
      subSection: 'bg-gray-200 border-b border-b-gray-200 font-bold',
      subTotal: 'bg-orange-100 border-b border-b-orange-300 font-bold',
      total: 'bg-orange-200 border-b border-b-orange-300 font-bold',
      head: 'bg-sky-100 border-t border-t-sky-500 font-medium',
      calculate: 'bg-green-100 border-y border-y-green-400 font-medium',
      calculateDerived: 'bg-white border-b border-b-gray-200 border-l border-l-green-400',
      calculateSubTotal: 'bg-green-100 border-b border-b-sky-500 font-medium',
      calculateTotal: 'bg-green-100 border-y border-y-green-400 font-bold',
      finalSubTotal: 'bg-orange-100 border-y border-y-orange-300 font-bold',
      finalTotal: 'bg-orange-200 border-y border-y-orange-300 font-bold',
      validateAlert: 'bg-white border-b border-b-red-400 rounded-bl-lg overflow-hidden',
      subValidateAlert: 'bg-white border-b border-b-red-400 border-l border-l-red-400',
      validatePass: 'bg-white border-b border-b-green-400 rounded-bl-lg overflow-hidden',
      subValidatePass: 'bg-white border-b border-b-green-400 border-l border-l-green-400',
      group: 'bg-white border-b border-b-gray-200',
      groupCalculate: 'bg-white border-b border-b-gray-200',
      groupReference: 'bg-white border-b border-b-gray-200',
      basic: 'bg-white border-b border-b-gray-200 border-l border-l-gray-200',
      udp: 'bg-sage-100 border-y border-y-sage-400 font-bold',
    } satisfies Record<LineTitleVariant, string>,
    closingSection: {
      true: '',
      false: '',
    },
    bottomBorder: {
      default: '',
      calculate: 'border-b-green-200',
      close: 'border-b-sky-500',
      none: 'border-b-0',
    },
  },
  defaultVariants: {
    variant: 'default',
    closingSection: false,
    bottomBorder: 'default',
  },
  compoundVariants: [
    {
      variant: ['default', 'calculateDerived', 'validateAlert', 'validatePass', 'subValidatePass', 'subValidateAlert'],
      class: 'text-xs tracking-[-0.24px]',
    },
    {
      variant: [
        'section',
        'subSectionProduction',
        'subSection',
        'subTotal',
        'total',
        'group',
        'groupCalculate',
        'groupReference',
        'basic',
        'calculate',
      ],
      class: 'text-sm tracking-[-0.28px]',
    },
    {
      variant: ['head', 'finalSubTotal', 'finalTotal', 'calculateSubTotal', 'calculateTotal'],
      class: 'text-base tracking-[-0.48px]',
    },
    {
      variant: ['group', 'subTotal', 'total'],
      closingSection: true,
      class: 'border-b border-b-sky-500',
    },
    {
      variant: ['calculateDerived', 'groupCalculate'],
      closingSection: true,
      class: 'border-b border-b-green-400',
    },
  ],
})

export interface LineTitleProps
  extends HtmlHTMLAttributes<HTMLDivElement>,
    VariantProps<typeof lineTitleVariants>,
    VariantProps<typeof lineTitleContainerVariants> {
  readonly label: string
  readonly symbol?: LineFormulaStripProps['symbol']
  readonly open?: boolean
  readonly collapsable?: boolean
  readonly onCollapse?: (open: boolean) => void
}

export const LineTitle = forwardRef<HTMLDivElement, LineTitleProps>(
  (
    {
      className,
      variant,
      withDividerRight,
      closingSection,
      bottomBorder,
      label,
      symbol = 'none',
      open,
      collapsable = true,
      onCollapse,
      children,
      ...props
    },
    ref
  ) => {
    const configuration = lineTitleConfiguration(variant, closingSection)
    const [isOpen, onOpenChange] = useControllableState({ prop: open, defaultProp: true, onChange: onCollapse })

    return (
      <div className={cn(lineTitleVariants({ variant, withDividerRight }), className)} ref={ref} {...props}>
        <LineFormulaStrip variant={configuration.formulaStrip} symbol={symbol} />
        <LineStrip
          data-variance={configuration.strip.variant}
          variant={configuration.strip.variant}
          configuration={configuration.strip.configuration}
          filled={symbol !== 'none' ? true : configuration.strip.filled}
          closingSection={closingSection || bottomBorder === 'close'}
        />
        {configuration.externalTab && <LineTab variant={configuration.externalTab} />}
        <div className={cn(lineTitleContainerVariants({ variant, closingSection, bottomBorder }))}>
          {configuration.tab && <LineTab variant={configuration.tab} />}
          {configuration.collapsable ? (
            <div className="flex w-full justify-items-stretch gap-1.5">
              <button
                type="button"
                className={lineLabelVariants({ variant })}
                tabIndex={-1}
                onClick={() => {
                  onOpenChange((o) => !o)
                }}>
                {collapsable && configuration.collapsable && (
                  <LineCollapsable open={isOpen} tabIndex={-1} className="self-center" />
                )}
                <p className="overflow-hidden text-ellipsis">{label}</p>
              </button>
              {children}
            </div>
          ) : (
            label && (
              <div className={cn(lineLabelVariants({ variant }), 'place-content-between truncate')}>
                <p className="overflow-hidden text-ellipsis" title={label}>
                  {label}
                </p>
                {children}
              </div>
            )
          )}
        </div>
      </div>
    )
  }
)
