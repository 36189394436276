import { type FC } from 'react'

import { MilkConfigurationView } from '@via/components'

import { useMilkConfigurationPage } from './useMilkConfigurationPage.ts'

export const MilkConfigurationPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useMilkConfigurationPage()
  return <MilkConfigurationView key={props.scenario._id} snapshot={snapshot} {...props} />
}
