import { type ScenarioState } from '@via/compute'

import { milkResultsSheet2024Blueprint } from './2024/milkResultsSheet2024Blueprint.ts'
import { type InferMonetaryTableBlueprint } from './inferMonetaryTableBlueprint.ts'

export type MilkResultsBlueprint = InferMonetaryTableBlueprint<typeof milkResultsSheet2024Blueprint>

export const milkResultsBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...milkResultsSheet2024Blueprint }
  }
}
