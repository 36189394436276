import { type BlueprintVersion, type DataKey, isDataKey, resolveAttributes } from '@via/schema'
import { BigNumber } from 'bignumber.js'
import { type Except } from 'type-fest'

import { cn } from '../../lib/utils'
import { type OverridableMonetaryCellData } from '../../model'
import { InlineEditingCellForm } from '../../molecules/InlineEditingCell/InlineEditingCellForm.tsx'
import { MonetaryProjectionWidget } from '../../monetary/MonetaryProjectionWidget/MonetaryProjectionWidget'

import { MonetaryReadonlyValueCell } from './MonetaryReadonlyValueCell.tsx'
import { PopoverEditableValueCell, type PopoverEditableValueCellProps } from './PopoverEditableValueCell'
import { type UpdateCellValue } from './UpdateCellValue.ts'

interface EditableProjectionValueCellProps extends Except<PopoverEditableValueCellProps, 'children'> {
  readonly dataKey: DataKey
  readonly blueprintVersion: BlueprintVersion
  readonly onUpdateCell: UpdateCellValue<'result-projections'>
  readonly weight?: string
}

const computeOverride = (
  key: DataKey,
  blueprintVersion: BlueprintVersion,
  cellData?: OverridableMonetaryCellData,
  value?: string,
  weight?: DataKey
) => {
  if (!value) {
    return {}
  }
  const attributes = resolveAttributes(key, blueprintVersion)
  if (attributes?.type === 'overridable' && cellData?.baseValue) {
    const diff = new BigNumber(cellData?.baseValue).minus(value)
    return diff.gte(0) ? { subtract: diff.toString() } : { add: diff.negated().toString() }
  }
  return { value, ...(weight ? { weight } : {}) }
}

const isWeightDataKey = (weight: string | undefined): weight is DataKey | undefined =>
  weight === undefined || isDataKey(weight)

export const EditableProjectionValueCell = ({
  dataKey,
  blueprintVersion,
  cellData,
  onUpdateCell,
  weight,
  className,
  ...props
}: EditableProjectionValueCellProps) => {
  if (!isWeightDataKey(weight)) {
    return <MonetaryReadonlyValueCell className={className} {...props} />
  }

  return (
    <PopoverEditableValueCell cellData={cellData} className={className} {...props}>
      {({ type, onClose }) =>
        type === 'inline' ? (
          <InlineEditingCellForm
            value={cellData.value}
            onConfirm={async (value) => {
              await onUpdateCell({
                type: 'result-projections',
                dataKey,
                override: computeOverride(dataKey, blueprintVersion, cellData, value, weight),
              })
              onClose()
            }}
            className={cn('-mt-6', className)}
          />
        ) : (
          <MonetaryProjectionWidget
            dataKey={dataKey}
            blueprintVersion={blueprintVersion}
            weight={weight}
            value={cellData}
            onConfirm={async ({ override, ...value }) => {
              await onUpdateCell({
                type: 'result-projections',
                dataKey,
                ...(override ? { override } : {}),
                ...value,
              })
              onClose()
            }}
            onApply={async ({ override, ...value }) => {
              await onUpdateCell({
                type: 'result-projections',
                dataKey,
                ...(override ? { override } : {}),
                ...value,
              })
            }}
          />
        )
      }
    </PopoverEditableValueCell>
  )
}
