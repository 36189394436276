import { type LineCellProps } from '../LineCell/LineCell.tsx'

import { type LineVariant } from './LineVariant.ts'

const LINE_CELL_VARIANT: Partial<Record<LineVariant, LineCellProps['variant']>> = {
  calculate: 'calculate',
  calculateDerived: 'calculateDerived',
  calculateSubTotal: 'calculateSubTotal',
  calculateTotal: 'calculateTotal',
  section: 'section',
  total: 'total',
  subSectionProduction: 'subSection',
  subSection: 'subSection',
  subTotal: 'subTotal',
  validatePass: 'validatePass',
  validateAlert: 'validateAlertEmpty',
  finalSubTotal: 'finalSubTotal',
  finalTotal: 'finalTotal',
  subValidatePass: 'validatePass',
  subValidateAlert: 'validateAlertEmpty',
  groupCalculate: 'readonlyValue',
  groupReference: 'readonlyValue',
  head: 'head',
  udp: 'udp',
}

export const lineToCellVariant = (
  lineVariant: LineVariant,
  defaultValue: LineCellProps['variant'] = 'default'
): LineCellProps['variant'] => LINE_CELL_VARIANT[lineVariant] ?? defaultValue
