import { type ScenarioState } from '@via/compute'

import { cropsResultsUdp2024Blueprint } from './2024/cropsResultsUdp2024Blueprint.ts'

export const cropsResultsUdpBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...cropsResultsUdp2024Blueprint }
  }
}
