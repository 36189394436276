import { createFileRoute } from '@tanstack/react-router'

import { ScenarioMaintenancePage } from '../pages/scenarioMaintenance/ScenarioMaintenancePage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/maintenance')({
  component: ScenarioMaintenancePage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'maintenance')
  },
})
