import { createFileRoute } from '@tanstack/react-router'

import { ScenarioOverheadsPage } from '../pages/scenarioOverheads/ScenarioOverheadsPage.tsx'
import { setScenarioCurrentPage } from '../pages/scenarioUpdate/utils/scenarioPage.ts'

export const Route = createFileRoute('/budgets/$budgetId/scenarios/$scenarioId/overheads')({
  component: ScenarioOverheadsPage,
  loader: ({ params }) => {
    setScenarioCurrentPage(params.budgetId, params.scenarioId, 'overheads')
  },
})
