import { type FinanceViewProps } from '@via/components'

import { useBudgetCallback } from '../../actions/useBudgetCallback.ts'
import { useCurrentScenarioHandler } from '../../contexts/currentScenario/useCurrentScenarioHandler.ts'
import { useScenarioLayoutPage } from '../scenario/useScenarioLayoutPage.ts'

export const useScenarioFinancePage = (): FinanceViewProps => {
  const props = useScenarioLayoutPage('finance')
  const { createLoan, updateLoan, deleteLoan, addInvestment, updateInvestment, deleteInvestment } =
    useCurrentScenarioHandler()

  const onAddLoan = useBudgetCallback('onAddLoan', createLoan)
  const onUpdateLoan = useBudgetCallback('onUpdateLoan', updateLoan)
  const onDeleteLoan = useBudgetCallback('onDeleteLoan', deleteLoan)
  const onAddInvestment = useBudgetCallback('onAddInvestment', addInvestment)
  const onUpdateInvestment = useBudgetCallback('onUpdateInvestment', updateInvestment)
  const onDeleteInvestment = useBudgetCallback('onDeleteInvestment', deleteInvestment)

  return {
    ...props,
    onAddLoan,
    onUpdateLoan,
    onDeleteLoan,
    onAddInvestment,
    onUpdateInvestment,
    onDeleteInvestment,
  }
}
