import { type FC } from 'react'

import { ScenarioOverheadsView } from '@via/components'

import { useScenarioOverheadsPage } from './useScenarioOverheadsPage.ts'

export const ScenarioOverheadsPage: FC<{ readonly snapshot?: boolean }> = ({ snapshot }) => {
  const props = useScenarioOverheadsPage()
  return <ScenarioOverheadsView key={props.scenario._id} snapshot={snapshot} {...props} />
}
