import { forwardRef } from 'react'
import { useIntl } from 'react-intl'

import { type BlueprintVersion, type DataKey, resolveAttributes } from '@via/schema'

import { GrbfProjectionWithGrowthWidget } from '../GrbfProjectionWithGrowthWidget/GrbfProjectionWithGrowthWidget.tsx'
import { MonetaryOverrideWidget } from '../MonetaryOverrideWidget/MonetaryOverrideWidget.tsx'
import { type MonetaryWidgetFormSubmitProviderProps } from '../MonetaryWidgetFormSubmitProvider'
import { OverridableProjectionWidget } from '../OverridableProjectionWidget/OverridableProjectionWidget.tsx'

export interface MonetaryProjectionWidgetProps extends MonetaryWidgetFormSubmitProviderProps {
  readonly dataKey: DataKey
  readonly weight?: DataKey
  readonly blueprintVersion: BlueprintVersion
}

export const MonetaryProjectionWidget = forwardRef<HTMLFormElement, MonetaryProjectionWidgetProps>(
  ({ dataKey, blueprintVersion, ...props }, ref) => {
    const intl = useIntl()
    const attributes = resolveAttributes(dataKey, blueprintVersion)
    switch (attributes?.type) {
      case 'grbf':
        return attributes.growth === false ? (
          <MonetaryOverrideWidget ref={ref} {...props} step="0.01" />
        ) : (
          <GrbfProjectionWithGrowthWidget ref={ref} {...props} />
        )
      case 'overridable':
        return (
          <OverridableProjectionWidget
            ref={ref}
            {...props}
            baseValueLabel={intl.formatMessage({ id: `table.${attributes.source}` })}
            navigationId="finance"
          />
        )

      default:
        return null
    }
  }
)
