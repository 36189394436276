import { createFileRoute } from '@tanstack/react-router'

import { ScenarioMaintenancePage } from '../pages/scenarioMaintenance/ScenarioMaintenancePage.tsx'

const MaintenanceComponent = () => <ScenarioMaintenancePage snapshot />

export const Route = createFileRoute(
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
)({
  component: MaintenanceComponent,
})
