import { createContext } from 'react'

import { type YearKey } from '@via/schema'

export interface MonetaryTablePropsContextValue {
  readonly readonly: boolean
  readonly selectedReferenceYear?: YearKey | 'EMPTY'
  readonly referenceType?: 'viagritel' | 'empty'
  readonly collapsedReference?: 'selected' | 'latest'
  readonly customReferenceLabel?: string
  readonly references: ReadonlyArray<YearKey>
  readonly projections: ReadonlyArray<YearKey>
  readonly readonlyReferences?: boolean
  readonly stickyTop: number
  readonly stickyLeft: number
  readonly withNavigation: boolean
}

export const MonetaryTablePropsContext = createContext<MonetaryTablePropsContextValue>({
  readonly: true,
  references: [],
  projections: [],
  stickyTop: 0,
  stickyLeft: 0,
  withNavigation: true,
})
