import { forwardRef } from 'react'

import { type Unit } from '@via/schema'
import { cva } from 'class-variance-authority'

import { LineCell, type LineCellProps, type LineCellVariant } from '../../atoms/LineCell/LineCell'
import { UnitLabel } from '../../atoms/UnitLabel/UnitLabel.tsx'

export interface LineCellWithUnitProps extends LineCellProps {
  readonly unit?: Unit | false
}

const lineUnitVariants = cva('', {
  variants: {
    variant: {
      default: '',
      emptyCell: 'text-transparent',
      readonlyValue: '',
      referenceValue: '',
      editableValue: '',
      grbfValue: 'text-gray-400',
      growthValue: 'text-gray-400',
      overridableValue: 'text-gray-400',
      section: '',
      subSection: '',
      subSectionProduction: '',
      subTotal: 'text-gray-900',
      total: 'text-gray-900',
      validatePass: '',
      validateAlert: '',
      validateAlertEmpty: '',
      calculate: 'text-gray-900',
      calculateDerived: '',
      calculateSubTotal: '',
      calculateTotal: '',
      finalSubTotal: '',
      finalTotal: 'text-gray-800',
      head: 'text-gray-800',
      udp: '',
    } satisfies Record<LineCellVariant, string>,
    dataStatus: {
      default: '',
      overridden: '',
      impacted: '',
      updated: '',
      imported: '',
      loading: 'hidden',
    },
  },
  defaultVariants: {
    variant: 'default',
    dataStatus: 'default',
  },
  compoundVariants: [
    {
      variant: ['editableValue', 'grbfValue', 'overridableValue'],
      dataStatus: 'default',
      class: 'text-gray-400',
    },
  ],
})

export const LineCellWithUnit = forwardRef<HTMLDivElement, LineCellWithUnitProps>(
  ({ unit, numberDisplayType, variant, dataStatus, value, ...props }, ref) => (
    <LineCell
      variant={variant}
      numberDisplayType={unit === false || unit === 'none' ? numberDisplayType : (unit ?? numberDisplayType)}
      dataStatus={dataStatus}
      value={value}
      {...props}
      ref={ref}>
      {unit && (
        <UnitLabel unit={unit} dimension="xs" className={lineUnitVariants({ variant, dataStatus })} value={value} />
      )}
    </LineCell>
  )
)
