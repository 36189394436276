/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as ClientsImport } from './routes/clients'
import { Route as BudgetListImport } from './routes/_budgetList'
import { Route as IndexImport } from './routes/index'
import { Route as ProfileIndexImport } from './routes/profile.index'
import { Route as ClientsIndexImport } from './routes/clients.index'
import { Route as ClientsClientIdImport } from './routes/clients.$clientId'
import { Route as BudgetsBudgetIdImport } from './routes/budgets.$budgetId'
import { Route as ClientsClientIdIndexImport } from './routes/clients.$clientId.index'
import { Route as BudgetsBudgetIdIndexImport } from './routes/budgets.$budgetId.index'
import { Route as BudgetListBudgetsIndexImport } from './routes/_budgetList.budgets.index'
import { Route as BudgetsBudgetIdReportsImport } from './routes/budgets.$budgetId.reports'
import { Route as BudgetsBudgetIdReportsIndexImport } from './routes/budgets.$budgetId.reports.index'
import { Route as BudgetsBudgetIdScenariosCreateImport } from './routes/budgets.$budgetId.scenarios.create'
import { Route as BudgetsBudgetIdScenariosScenarioIdImport } from './routes/budgets.$budgetId.scenarios.$scenarioId'
import { Route as BudgetsBudgetIdReportsReportIdImport } from './routes/budgets.$budgetId.reports.$reportId'
import { Route as BudgetsBudgetIdScenariosScenarioIdIndexImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.index'
import { Route as BudgetsBudgetIdReportsReportIdIndexImport } from './routes/budgets.$budgetId.reports.$reportId.index'
import { Route as BudgetsBudgetIdScenariosScenarioIdResultsImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.results'
import { Route as BudgetsBudgetIdScenariosScenarioIdRepaymentImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.repayment'
import { Route as BudgetsBudgetIdScenariosScenarioIdOverheadsImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.overheads'
import { Route as BudgetsBudgetIdScenariosScenarioIdMilkResultsImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.milk-results'
import { Route as BudgetsBudgetIdScenariosScenarioIdMilkConfigurationImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.milk-configuration'
import { Route as BudgetsBudgetIdScenariosScenarioIdMilkAnimalsImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.milk-animals'
import { Route as BudgetsBudgetIdScenariosScenarioIdMaintenanceImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.maintenance'
import { Route as BudgetsBudgetIdScenariosScenarioIdFinanceImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.finance'
import { Route as BudgetsBudgetIdScenariosScenarioIdCropsResultsImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.crops-results'
import { Route as BudgetsBudgetIdScenariosScenarioIdConfigurationImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.configuration'
import { Route as BudgetsBudgetIdScenariosScenarioIdBalanceImport } from './routes/budgets.$budgetId.scenarios.$scenarioId.balance'
import { Route as BudgetsBudgetIdReportsReportIdFilesIndexImport } from './routes/budgets.$budgetId.reports.$reportId.files.index'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdIndexImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.index'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.index'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.results'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.repayment'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.overheads'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-results'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-configuration'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-animals'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.maintenance'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.finance'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.crops-results'
import { Route as BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceImport } from './routes/budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.balance'

// Create/Update Routes

const ClientsRoute = ClientsImport.update({
  id: '/clients',
  path: '/clients',
  getParentRoute: () => rootRoute,
} as any)

const BudgetListRoute = BudgetListImport.update({
  id: '/_budgetList',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const ProfileIndexRoute = ProfileIndexImport.update({
  id: '/profile/',
  path: '/profile/',
  getParentRoute: () => rootRoute,
} as any)

const ClientsIndexRoute = ClientsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ClientsRoute,
} as any)

const ClientsClientIdRoute = ClientsClientIdImport.update({
  id: '/$clientId',
  path: '/$clientId',
  getParentRoute: () => ClientsRoute,
} as any)

const BudgetsBudgetIdRoute = BudgetsBudgetIdImport.update({
  id: '/budgets/$budgetId',
  path: '/budgets/$budgetId',
  getParentRoute: () => rootRoute,
} as any)

const ClientsClientIdIndexRoute = ClientsClientIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => ClientsClientIdRoute,
} as any)

const BudgetsBudgetIdIndexRoute = BudgetsBudgetIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => BudgetsBudgetIdRoute,
} as any)

const BudgetListBudgetsIndexRoute = BudgetListBudgetsIndexImport.update({
  id: '/budgets/',
  path: '/budgets/',
  getParentRoute: () => BudgetListRoute,
} as any)

const BudgetsBudgetIdReportsRoute = BudgetsBudgetIdReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => BudgetsBudgetIdRoute,
} as any)

const BudgetsBudgetIdReportsIndexRoute =
  BudgetsBudgetIdReportsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => BudgetsBudgetIdReportsRoute,
  } as any)

const BudgetsBudgetIdScenariosCreateRoute =
  BudgetsBudgetIdScenariosCreateImport.update({
    id: '/scenarios/create',
    path: '/scenarios/create',
    getParentRoute: () => BudgetsBudgetIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdRoute =
  BudgetsBudgetIdScenariosScenarioIdImport.update({
    id: '/scenarios/$scenarioId',
    path: '/scenarios/$scenarioId',
    getParentRoute: () => BudgetsBudgetIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdRoute =
  BudgetsBudgetIdReportsReportIdImport.update({
    id: '/$reportId',
    path: '/$reportId',
    getParentRoute: () => BudgetsBudgetIdReportsRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdIndexRoute =
  BudgetsBudgetIdScenariosScenarioIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdIndexRoute =
  BudgetsBudgetIdReportsReportIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => BudgetsBudgetIdReportsReportIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdResultsRoute =
  BudgetsBudgetIdScenariosScenarioIdResultsImport.update({
    id: '/results',
    path: '/results',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdRepaymentRoute =
  BudgetsBudgetIdScenariosScenarioIdRepaymentImport.update({
    id: '/repayment',
    path: '/repayment',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdOverheadsRoute =
  BudgetsBudgetIdScenariosScenarioIdOverheadsImport.update({
    id: '/overheads',
    path: '/overheads',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute =
  BudgetsBudgetIdScenariosScenarioIdMilkResultsImport.update({
    id: '/milk-results',
    path: '/milk-results',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute =
  BudgetsBudgetIdScenariosScenarioIdMilkConfigurationImport.update({
    id: '/milk-configuration',
    path: '/milk-configuration',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute =
  BudgetsBudgetIdScenariosScenarioIdMilkAnimalsImport.update({
    id: '/milk-animals',
    path: '/milk-animals',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute =
  BudgetsBudgetIdScenariosScenarioIdMaintenanceImport.update({
    id: '/maintenance',
    path: '/maintenance',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdFinanceRoute =
  BudgetsBudgetIdScenariosScenarioIdFinanceImport.update({
    id: '/finance',
    path: '/finance',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute =
  BudgetsBudgetIdScenariosScenarioIdCropsResultsImport.update({
    id: '/crops-results',
    path: '/crops-results',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdConfigurationRoute =
  BudgetsBudgetIdScenariosScenarioIdConfigurationImport.update({
    id: '/configuration',
    path: '/configuration',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdScenariosScenarioIdBalanceRoute =
  BudgetsBudgetIdScenariosScenarioIdBalanceImport.update({
    id: '/balance',
    path: '/balance',
    getParentRoute: () => BudgetsBudgetIdScenariosScenarioIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdFilesIndexRoute =
  BudgetsBudgetIdReportsReportIdFilesIndexImport.update({
    id: '/files/',
    path: '/files/',
    getParentRoute: () => BudgetsBudgetIdReportsReportIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdFilesFileIdRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdImport.update({
    id: '/files/$fileId',
    path: '/files/$fileId',
    getParentRoute: () => BudgetsBudgetIdReportsReportIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => BudgetsBudgetIdReportsReportIdFilesFileIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport.update({
    id: '/scenarios/$scenarioId',
    path: '/scenarios/$scenarioId',
    getParentRoute: () => BudgetsBudgetIdReportsReportIdFilesFileIdRoute,
  } as any)

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexImport.update(
    {
      id: '/',
      path: '/',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsImport.update(
    {
      id: '/results',
      path: '/results',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentImport.update(
    {
      id: '/repayment',
      path: '/repayment',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsImport.update(
    {
      id: '/overheads',
      path: '/overheads',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsImport.update(
    {
      id: '/milk-results',
      path: '/milk-results',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationImport.update(
    {
      id: '/milk-configuration',
      path: '/milk-configuration',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsImport.update(
    {
      id: '/milk-animals',
      path: '/milk-animals',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceImport.update(
    {
      id: '/maintenance',
      path: '/maintenance',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceImport.update(
    {
      id: '/finance',
      path: '/finance',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsImport.update(
    {
      id: '/crops-results',
      path: '/crops-results',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceImport.update(
    {
      id: '/balance',
      path: '/balance',
      getParentRoute: () =>
        BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute,
    } as any,
  )

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_budgetList': {
      id: '/_budgetList'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof BudgetListImport
      parentRoute: typeof rootRoute
    }
    '/clients': {
      id: '/clients'
      path: '/clients'
      fullPath: '/clients'
      preLoaderRoute: typeof ClientsImport
      parentRoute: typeof rootRoute
    }
    '/budgets/$budgetId': {
      id: '/budgets/$budgetId'
      path: '/budgets/$budgetId'
      fullPath: '/budgets/$budgetId'
      preLoaderRoute: typeof BudgetsBudgetIdImport
      parentRoute: typeof rootRoute
    }
    '/clients/$clientId': {
      id: '/clients/$clientId'
      path: '/$clientId'
      fullPath: '/clients/$clientId'
      preLoaderRoute: typeof ClientsClientIdImport
      parentRoute: typeof ClientsImport
    }
    '/clients/': {
      id: '/clients/'
      path: '/'
      fullPath: '/clients/'
      preLoaderRoute: typeof ClientsIndexImport
      parentRoute: typeof ClientsImport
    }
    '/profile/': {
      id: '/profile/'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProfileIndexImport
      parentRoute: typeof rootRoute
    }
    '/budgets/$budgetId/reports': {
      id: '/budgets/$budgetId/reports'
      path: '/reports'
      fullPath: '/budgets/$budgetId/reports'
      preLoaderRoute: typeof BudgetsBudgetIdReportsImport
      parentRoute: typeof BudgetsBudgetIdImport
    }
    '/_budgetList/budgets/': {
      id: '/_budgetList/budgets/'
      path: '/budgets'
      fullPath: '/budgets'
      preLoaderRoute: typeof BudgetListBudgetsIndexImport
      parentRoute: typeof BudgetListImport
    }
    '/budgets/$budgetId/': {
      id: '/budgets/$budgetId/'
      path: '/'
      fullPath: '/budgets/$budgetId/'
      preLoaderRoute: typeof BudgetsBudgetIdIndexImport
      parentRoute: typeof BudgetsBudgetIdImport
    }
    '/clients/$clientId/': {
      id: '/clients/$clientId/'
      path: '/'
      fullPath: '/clients/$clientId/'
      preLoaderRoute: typeof ClientsClientIdIndexImport
      parentRoute: typeof ClientsClientIdImport
    }
    '/budgets/$budgetId/reports/$reportId': {
      id: '/budgets/$budgetId/reports/$reportId'
      path: '/$reportId'
      fullPath: '/budgets/$budgetId/reports/$reportId'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdImport
      parentRoute: typeof BudgetsBudgetIdReportsImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId': {
      id: '/budgets/$budgetId/scenarios/$scenarioId'
      path: '/scenarios/$scenarioId'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
      parentRoute: typeof BudgetsBudgetIdImport
    }
    '/budgets/$budgetId/scenarios/create': {
      id: '/budgets/$budgetId/scenarios/create'
      path: '/scenarios/create'
      fullPath: '/budgets/$budgetId/scenarios/create'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosCreateImport
      parentRoute: typeof BudgetsBudgetIdImport
    }
    '/budgets/$budgetId/reports/': {
      id: '/budgets/$budgetId/reports/'
      path: '/'
      fullPath: '/budgets/$budgetId/reports/'
      preLoaderRoute: typeof BudgetsBudgetIdReportsIndexImport
      parentRoute: typeof BudgetsBudgetIdReportsImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/balance': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/balance'
      path: '/balance'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/balance'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdBalanceImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/configuration': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/configuration'
      path: '/configuration'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/configuration'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdConfigurationImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/crops-results': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/crops-results'
      path: '/crops-results'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/crops-results'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdCropsResultsImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/finance': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/finance'
      path: '/finance'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/finance'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdFinanceImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/maintenance': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/maintenance'
      path: '/maintenance'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/maintenance'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdMaintenanceImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/milk-animals': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/milk-animals'
      path: '/milk-animals'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/milk-animals'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkAnimalsImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration'
      path: '/milk-configuration'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkConfigurationImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/milk-results': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/milk-results'
      path: '/milk-results'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/milk-results'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkResultsImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/overheads': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/overheads'
      path: '/overheads'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/overheads'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdOverheadsImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/repayment': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/repayment'
      path: '/repayment'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/repayment'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdRepaymentImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/results': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/results'
      path: '/results'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/results'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdResultsImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/': {
      id: '/budgets/$budgetId/reports/$reportId/'
      path: '/'
      fullPath: '/budgets/$budgetId/reports/$reportId/'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdIndexImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdImport
    }
    '/budgets/$budgetId/scenarios/$scenarioId/': {
      id: '/budgets/$budgetId/scenarios/$scenarioId/'
      path: '/'
      fullPath: '/budgets/$budgetId/scenarios/$scenarioId/'
      preLoaderRoute: typeof BudgetsBudgetIdScenariosScenarioIdIndexImport
      parentRoute: typeof BudgetsBudgetIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId'
      path: '/files/$fileId'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/': {
      id: '/budgets/$budgetId/reports/$reportId/files/'
      path: '/files'
      fullPath: '/budgets/$budgetId/reports/$reportId/files'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesIndexImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/'
      path: '/'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdIndexImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId'
      path: '/scenarios/$scenarioId'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
      path: '/balance'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
      path: '/crops-results'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
      path: '/finance'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
      path: '/maintenance'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
      path: '/milk-animals'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
      path: '/milk-configuration'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
      path: '/milk-results'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
      path: '/overheads'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
      path: '/repayment'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
      path: '/results'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
    '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/': {
      id: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/'
      path: '/'
      fullPath: '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/'
      preLoaderRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexImport
      parentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdImport
    }
  }
}

// Create and export the route tree

interface BudgetListRouteChildren {
  BudgetListBudgetsIndexRoute: typeof BudgetListBudgetsIndexRoute
}

const BudgetListRouteChildren: BudgetListRouteChildren = {
  BudgetListBudgetsIndexRoute: BudgetListBudgetsIndexRoute,
}

const BudgetListRouteWithChildren = BudgetListRoute._addFileChildren(
  BudgetListRouteChildren,
)

interface ClientsClientIdRouteChildren {
  ClientsClientIdIndexRoute: typeof ClientsClientIdIndexRoute
}

const ClientsClientIdRouteChildren: ClientsClientIdRouteChildren = {
  ClientsClientIdIndexRoute: ClientsClientIdIndexRoute,
}

const ClientsClientIdRouteWithChildren = ClientsClientIdRoute._addFileChildren(
  ClientsClientIdRouteChildren,
)

interface ClientsRouteChildren {
  ClientsClientIdRoute: typeof ClientsClientIdRouteWithChildren
  ClientsIndexRoute: typeof ClientsIndexRoute
}

const ClientsRouteChildren: ClientsRouteChildren = {
  ClientsClientIdRoute: ClientsClientIdRouteWithChildren,
  ClientsIndexRoute: ClientsIndexRoute,
}

const ClientsRouteWithChildren =
  ClientsRoute._addFileChildren(ClientsRouteChildren)

interface BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteChildren {
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute
}

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteChildren: BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteChildren =
  {
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute,
  }

const BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteWithChildren =
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute._addFileChildren(
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteChildren,
  )

interface BudgetsBudgetIdReportsReportIdFilesFileIdRouteChildren {
  BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteWithChildren
}

const BudgetsBudgetIdReportsReportIdFilesFileIdRouteChildren: BudgetsBudgetIdReportsReportIdFilesFileIdRouteChildren =
  {
    BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteWithChildren,
  }

const BudgetsBudgetIdReportsReportIdFilesFileIdRouteWithChildren =
  BudgetsBudgetIdReportsReportIdFilesFileIdRoute._addFileChildren(
    BudgetsBudgetIdReportsReportIdFilesFileIdRouteChildren,
  )

interface BudgetsBudgetIdReportsReportIdRouteChildren {
  BudgetsBudgetIdReportsReportIdIndexRoute: typeof BudgetsBudgetIdReportsReportIdIndexRoute
  BudgetsBudgetIdReportsReportIdFilesFileIdRoute: typeof BudgetsBudgetIdReportsReportIdFilesFileIdRouteWithChildren
  BudgetsBudgetIdReportsReportIdFilesIndexRoute: typeof BudgetsBudgetIdReportsReportIdFilesIndexRoute
}

const BudgetsBudgetIdReportsReportIdRouteChildren: BudgetsBudgetIdReportsReportIdRouteChildren =
  {
    BudgetsBudgetIdReportsReportIdIndexRoute:
      BudgetsBudgetIdReportsReportIdIndexRoute,
    BudgetsBudgetIdReportsReportIdFilesFileIdRoute:
      BudgetsBudgetIdReportsReportIdFilesFileIdRouteWithChildren,
    BudgetsBudgetIdReportsReportIdFilesIndexRoute:
      BudgetsBudgetIdReportsReportIdFilesIndexRoute,
  }

const BudgetsBudgetIdReportsReportIdRouteWithChildren =
  BudgetsBudgetIdReportsReportIdRoute._addFileChildren(
    BudgetsBudgetIdReportsReportIdRouteChildren,
  )

interface BudgetsBudgetIdReportsRouteChildren {
  BudgetsBudgetIdReportsReportIdRoute: typeof BudgetsBudgetIdReportsReportIdRouteWithChildren
  BudgetsBudgetIdReportsIndexRoute: typeof BudgetsBudgetIdReportsIndexRoute
}

const BudgetsBudgetIdReportsRouteChildren: BudgetsBudgetIdReportsRouteChildren =
  {
    BudgetsBudgetIdReportsReportIdRoute:
      BudgetsBudgetIdReportsReportIdRouteWithChildren,
    BudgetsBudgetIdReportsIndexRoute: BudgetsBudgetIdReportsIndexRoute,
  }

const BudgetsBudgetIdReportsRouteWithChildren =
  BudgetsBudgetIdReportsRoute._addFileChildren(
    BudgetsBudgetIdReportsRouteChildren,
  )

interface BudgetsBudgetIdScenariosScenarioIdRouteChildren {
  BudgetsBudgetIdScenariosScenarioIdBalanceRoute: typeof BudgetsBudgetIdScenariosScenarioIdBalanceRoute
  BudgetsBudgetIdScenariosScenarioIdConfigurationRoute: typeof BudgetsBudgetIdScenariosScenarioIdConfigurationRoute
  BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute: typeof BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute
  BudgetsBudgetIdScenariosScenarioIdFinanceRoute: typeof BudgetsBudgetIdScenariosScenarioIdFinanceRoute
  BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute: typeof BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute
  BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute
  BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute
  BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute: typeof BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute
  BudgetsBudgetIdScenariosScenarioIdOverheadsRoute: typeof BudgetsBudgetIdScenariosScenarioIdOverheadsRoute
  BudgetsBudgetIdScenariosScenarioIdRepaymentRoute: typeof BudgetsBudgetIdScenariosScenarioIdRepaymentRoute
  BudgetsBudgetIdScenariosScenarioIdResultsRoute: typeof BudgetsBudgetIdScenariosScenarioIdResultsRoute
  BudgetsBudgetIdScenariosScenarioIdIndexRoute: typeof BudgetsBudgetIdScenariosScenarioIdIndexRoute
}

const BudgetsBudgetIdScenariosScenarioIdRouteChildren: BudgetsBudgetIdScenariosScenarioIdRouteChildren =
  {
    BudgetsBudgetIdScenariosScenarioIdBalanceRoute:
      BudgetsBudgetIdScenariosScenarioIdBalanceRoute,
    BudgetsBudgetIdScenariosScenarioIdConfigurationRoute:
      BudgetsBudgetIdScenariosScenarioIdConfigurationRoute,
    BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute:
      BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute,
    BudgetsBudgetIdScenariosScenarioIdFinanceRoute:
      BudgetsBudgetIdScenariosScenarioIdFinanceRoute,
    BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute:
      BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute,
    BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute:
      BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute,
    BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute:
      BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute,
    BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute:
      BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute,
    BudgetsBudgetIdScenariosScenarioIdOverheadsRoute:
      BudgetsBudgetIdScenariosScenarioIdOverheadsRoute,
    BudgetsBudgetIdScenariosScenarioIdRepaymentRoute:
      BudgetsBudgetIdScenariosScenarioIdRepaymentRoute,
    BudgetsBudgetIdScenariosScenarioIdResultsRoute:
      BudgetsBudgetIdScenariosScenarioIdResultsRoute,
    BudgetsBudgetIdScenariosScenarioIdIndexRoute:
      BudgetsBudgetIdScenariosScenarioIdIndexRoute,
  }

const BudgetsBudgetIdScenariosScenarioIdRouteWithChildren =
  BudgetsBudgetIdScenariosScenarioIdRoute._addFileChildren(
    BudgetsBudgetIdScenariosScenarioIdRouteChildren,
  )

interface BudgetsBudgetIdRouteChildren {
  BudgetsBudgetIdReportsRoute: typeof BudgetsBudgetIdReportsRouteWithChildren
  BudgetsBudgetIdIndexRoute: typeof BudgetsBudgetIdIndexRoute
  BudgetsBudgetIdScenariosScenarioIdRoute: typeof BudgetsBudgetIdScenariosScenarioIdRouteWithChildren
  BudgetsBudgetIdScenariosCreateRoute: typeof BudgetsBudgetIdScenariosCreateRoute
}

const BudgetsBudgetIdRouteChildren: BudgetsBudgetIdRouteChildren = {
  BudgetsBudgetIdReportsRoute: BudgetsBudgetIdReportsRouteWithChildren,
  BudgetsBudgetIdIndexRoute: BudgetsBudgetIdIndexRoute,
  BudgetsBudgetIdScenariosScenarioIdRoute:
    BudgetsBudgetIdScenariosScenarioIdRouteWithChildren,
  BudgetsBudgetIdScenariosCreateRoute: BudgetsBudgetIdScenariosCreateRoute,
}

const BudgetsBudgetIdRouteWithChildren = BudgetsBudgetIdRoute._addFileChildren(
  BudgetsBudgetIdRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof BudgetListRouteWithChildren
  '/clients': typeof ClientsRouteWithChildren
  '/budgets/$budgetId': typeof BudgetsBudgetIdRouteWithChildren
  '/clients/$clientId': typeof ClientsClientIdRouteWithChildren
  '/clients/': typeof ClientsIndexRoute
  '/profile': typeof ProfileIndexRoute
  '/budgets/$budgetId/reports': typeof BudgetsBudgetIdReportsRouteWithChildren
  '/budgets': typeof BudgetListBudgetsIndexRoute
  '/budgets/$budgetId/': typeof BudgetsBudgetIdIndexRoute
  '/clients/$clientId/': typeof ClientsClientIdIndexRoute
  '/budgets/$budgetId/reports/$reportId': typeof BudgetsBudgetIdReportsReportIdRouteWithChildren
  '/budgets/$budgetId/scenarios/$scenarioId': typeof BudgetsBudgetIdScenariosScenarioIdRouteWithChildren
  '/budgets/$budgetId/scenarios/create': typeof BudgetsBudgetIdScenariosCreateRoute
  '/budgets/$budgetId/reports/': typeof BudgetsBudgetIdReportsIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/configuration': typeof BudgetsBudgetIdScenariosScenarioIdConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId/': typeof BudgetsBudgetIdReportsReportIdIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId/': typeof BudgetsBudgetIdScenariosScenarioIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdRouteWithChildren
  '/budgets/$budgetId/reports/$reportId/files': typeof BudgetsBudgetIdReportsReportIdFilesIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/': typeof BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteWithChildren
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof BudgetListRouteWithChildren
  '/clients': typeof ClientsIndexRoute
  '/profile': typeof ProfileIndexRoute
  '/budgets': typeof BudgetListBudgetsIndexRoute
  '/budgets/$budgetId': typeof BudgetsBudgetIdIndexRoute
  '/clients/$clientId': typeof ClientsClientIdIndexRoute
  '/budgets/$budgetId/scenarios/create': typeof BudgetsBudgetIdScenariosCreateRoute
  '/budgets/$budgetId/reports': typeof BudgetsBudgetIdReportsIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/configuration': typeof BudgetsBudgetIdScenariosScenarioIdConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId': typeof BudgetsBudgetIdReportsReportIdIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId': typeof BudgetsBudgetIdScenariosScenarioIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files': typeof BudgetsBudgetIdReportsReportIdFilesIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_budgetList': typeof BudgetListRouteWithChildren
  '/clients': typeof ClientsRouteWithChildren
  '/budgets/$budgetId': typeof BudgetsBudgetIdRouteWithChildren
  '/clients/$clientId': typeof ClientsClientIdRouteWithChildren
  '/clients/': typeof ClientsIndexRoute
  '/profile/': typeof ProfileIndexRoute
  '/budgets/$budgetId/reports': typeof BudgetsBudgetIdReportsRouteWithChildren
  '/_budgetList/budgets/': typeof BudgetListBudgetsIndexRoute
  '/budgets/$budgetId/': typeof BudgetsBudgetIdIndexRoute
  '/clients/$clientId/': typeof ClientsClientIdIndexRoute
  '/budgets/$budgetId/reports/$reportId': typeof BudgetsBudgetIdReportsReportIdRouteWithChildren
  '/budgets/$budgetId/scenarios/$scenarioId': typeof BudgetsBudgetIdScenariosScenarioIdRouteWithChildren
  '/budgets/$budgetId/scenarios/create': typeof BudgetsBudgetIdScenariosCreateRoute
  '/budgets/$budgetId/reports/': typeof BudgetsBudgetIdReportsIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/configuration': typeof BudgetsBudgetIdScenariosScenarioIdConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId/': typeof BudgetsBudgetIdReportsReportIdIndexRoute
  '/budgets/$budgetId/scenarios/$scenarioId/': typeof BudgetsBudgetIdScenariosScenarioIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdRouteWithChildren
  '/budgets/$budgetId/reports/$reportId/files/': typeof BudgetsBudgetIdReportsReportIdFilesIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/': typeof BudgetsBudgetIdReportsReportIdFilesFileIdIndexRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRouteWithChildren
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdBalanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdCropsResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdFinanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMaintenanceRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkAnimalsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkConfigurationRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdMilkResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdOverheadsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdRepaymentRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdResultsRoute
  '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/': typeof BudgetsBudgetIdReportsReportIdFilesFileIdScenariosScenarioIdIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/clients'
    | '/budgets/$budgetId'
    | '/clients/$clientId'
    | '/clients/'
    | '/profile'
    | '/budgets/$budgetId/reports'
    | '/budgets'
    | '/budgets/$budgetId/'
    | '/clients/$clientId/'
    | '/budgets/$budgetId/reports/$reportId'
    | '/budgets/$budgetId/scenarios/$scenarioId'
    | '/budgets/$budgetId/scenarios/create'
    | '/budgets/$budgetId/reports/'
    | '/budgets/$budgetId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/scenarios/$scenarioId/configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId/'
    | '/budgets/$budgetId/scenarios/$scenarioId/'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId'
    | '/budgets/$budgetId/reports/$reportId/files'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/clients'
    | '/profile'
    | '/budgets'
    | '/budgets/$budgetId'
    | '/clients/$clientId'
    | '/budgets/$budgetId/scenarios/create'
    | '/budgets/$budgetId/reports'
    | '/budgets/$budgetId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/scenarios/$scenarioId/configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId'
    | '/budgets/$budgetId/scenarios/$scenarioId'
    | '/budgets/$budgetId/reports/$reportId/files'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId'
  id:
    | '__root__'
    | '/'
    | '/_budgetList'
    | '/clients'
    | '/budgets/$budgetId'
    | '/clients/$clientId'
    | '/clients/'
    | '/profile/'
    | '/budgets/$budgetId/reports'
    | '/_budgetList/budgets/'
    | '/budgets/$budgetId/'
    | '/clients/$clientId/'
    | '/budgets/$budgetId/reports/$reportId'
    | '/budgets/$budgetId/scenarios/$scenarioId'
    | '/budgets/$budgetId/scenarios/create'
    | '/budgets/$budgetId/reports/'
    | '/budgets/$budgetId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/scenarios/$scenarioId/configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId/'
    | '/budgets/$budgetId/scenarios/$scenarioId/'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId'
    | '/budgets/$budgetId/reports/$reportId/files/'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results'
    | '/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  BudgetListRoute: typeof BudgetListRouteWithChildren
  ClientsRoute: typeof ClientsRouteWithChildren
  BudgetsBudgetIdRoute: typeof BudgetsBudgetIdRouteWithChildren
  ProfileIndexRoute: typeof ProfileIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  BudgetListRoute: BudgetListRouteWithChildren,
  ClientsRoute: ClientsRouteWithChildren,
  BudgetsBudgetIdRoute: BudgetsBudgetIdRouteWithChildren,
  ProfileIndexRoute: ProfileIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_budgetList",
        "/clients",
        "/budgets/$budgetId",
        "/profile/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_budgetList": {
      "filePath": "_budgetList.tsx",
      "children": [
        "/_budgetList/budgets/"
      ]
    },
    "/clients": {
      "filePath": "clients.tsx",
      "children": [
        "/clients/$clientId",
        "/clients/"
      ]
    },
    "/budgets/$budgetId": {
      "filePath": "budgets.$budgetId.tsx",
      "children": [
        "/budgets/$budgetId/reports",
        "/budgets/$budgetId/",
        "/budgets/$budgetId/scenarios/$scenarioId",
        "/budgets/$budgetId/scenarios/create"
      ]
    },
    "/clients/$clientId": {
      "filePath": "clients.$clientId.tsx",
      "parent": "/clients",
      "children": [
        "/clients/$clientId/"
      ]
    },
    "/clients/": {
      "filePath": "clients.index.tsx",
      "parent": "/clients"
    },
    "/profile/": {
      "filePath": "profile.index.tsx"
    },
    "/budgets/$budgetId/reports": {
      "filePath": "budgets.$budgetId.reports.tsx",
      "parent": "/budgets/$budgetId",
      "children": [
        "/budgets/$budgetId/reports/$reportId",
        "/budgets/$budgetId/reports/"
      ]
    },
    "/_budgetList/budgets/": {
      "filePath": "_budgetList.budgets.index.tsx",
      "parent": "/_budgetList"
    },
    "/budgets/$budgetId/": {
      "filePath": "budgets.$budgetId.index.tsx",
      "parent": "/budgets/$budgetId"
    },
    "/clients/$clientId/": {
      "filePath": "clients.$clientId.index.tsx",
      "parent": "/clients/$clientId"
    },
    "/budgets/$budgetId/reports/$reportId": {
      "filePath": "budgets.$budgetId.reports.$reportId.tsx",
      "parent": "/budgets/$budgetId/reports",
      "children": [
        "/budgets/$budgetId/reports/$reportId/",
        "/budgets/$budgetId/reports/$reportId/files/$fileId",
        "/budgets/$budgetId/reports/$reportId/files/"
      ]
    },
    "/budgets/$budgetId/scenarios/$scenarioId": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.tsx",
      "parent": "/budgets/$budgetId",
      "children": [
        "/budgets/$budgetId/scenarios/$scenarioId/balance",
        "/budgets/$budgetId/scenarios/$scenarioId/configuration",
        "/budgets/$budgetId/scenarios/$scenarioId/crops-results",
        "/budgets/$budgetId/scenarios/$scenarioId/finance",
        "/budgets/$budgetId/scenarios/$scenarioId/maintenance",
        "/budgets/$budgetId/scenarios/$scenarioId/milk-animals",
        "/budgets/$budgetId/scenarios/$scenarioId/milk-configuration",
        "/budgets/$budgetId/scenarios/$scenarioId/milk-results",
        "/budgets/$budgetId/scenarios/$scenarioId/overheads",
        "/budgets/$budgetId/scenarios/$scenarioId/repayment",
        "/budgets/$budgetId/scenarios/$scenarioId/results",
        "/budgets/$budgetId/scenarios/$scenarioId/"
      ]
    },
    "/budgets/$budgetId/scenarios/create": {
      "filePath": "budgets.$budgetId.scenarios.create.tsx",
      "parent": "/budgets/$budgetId"
    },
    "/budgets/$budgetId/reports/": {
      "filePath": "budgets.$budgetId.reports.index.tsx",
      "parent": "/budgets/$budgetId/reports"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/balance": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.balance.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/configuration": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.configuration.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/crops-results": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.crops-results.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/finance": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.finance.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/maintenance": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.maintenance.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/milk-animals": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.milk-animals.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/milk-configuration": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.milk-configuration.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/milk-results": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.milk-results.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/overheads": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.overheads.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/repayment": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.repayment.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/results": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.results.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/": {
      "filePath": "budgets.$budgetId.reports.$reportId.index.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId"
    },
    "/budgets/$budgetId/scenarios/$scenarioId/": {
      "filePath": "budgets.$budgetId.scenarios.$scenarioId.index.tsx",
      "parent": "/budgets/$budgetId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId",
      "children": [
        "/budgets/$budgetId/reports/$reportId/files/$fileId/",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
      ]
    },
    "/budgets/$budgetId/reports/$reportId/files/": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.index.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.index.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId",
      "children": [
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results",
        "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/"
      ]
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/balance": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.balance.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/crops-results": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.crops-results.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/finance": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.finance.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/maintenance": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.maintenance.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-animals": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-animals.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-configuration": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-configuration.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/milk-results": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.milk-results.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/overheads": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.overheads.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/repayment": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.repayment.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/results": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.results.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    },
    "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId/": {
      "filePath": "budgets.$budgetId.reports.$reportId.files.$fileId.scenarios.$scenarioId.index.tsx",
      "parent": "/budgets/$budgetId/reports/$reportId/files/$fileId/scenarios/$scenarioId"
    }
  }
}
ROUTE_MANIFEST_END */
