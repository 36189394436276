import { useContext } from 'react'

import { type UseMonetaryRowDataOptions } from '../../context/monetary/types.ts'
import { useMonetaryRowData } from '../../context/monetary/useMonetaryRowData.ts'

import { MonetaryTableBlueprintContext } from './contexts/MonetaryTableBlueprintContext.ts'
import { MonetaryTableCurrentBlueprintTabContext } from './contexts/MonetaryTableCurrentBlueprintTabContext.ts'
import { MonetaryTableCurrentBlueprintWeightContext } from './contexts/MonetaryTableCurrentBlueprintWeightContext.ts'

type UseMonetaryTableDataOptions<Weight extends string> = UseMonetaryRowDataOptions<Weight>

export const useMonetaryTableData = <Weight extends string = never>({
  computation,
  weight,
  ...options
}: UseMonetaryTableDataOptions<Weight>) => {
  const blueprint = useContext(MonetaryTableBlueprintContext)
  const currentTab = useContext(MonetaryTableCurrentBlueprintTabContext)
  const currentBlueprintWeight = useContext(MonetaryTableCurrentBlueprintWeightContext)
  const currentWeight = currentBlueprintWeight ? blueprint.weights?.[currentBlueprintWeight] : undefined
  return useMonetaryRowData<Weight>({
    computation: computation ?? currentTab,
    weight: currentWeight ?? weight,
    ...options,
  })
}
