import { type ScenarioState } from '@via/compute'

import { repaymentCapacity2024Blueprint } from './2024/repaymentCapacity2024Blueprint.ts'
import { type InferMonetaryTableBlueprint } from './inferMonetaryTableBlueprint.ts'

export type RepaymentCapacityBlueprint = InferMonetaryTableBlueprint<ReturnType<typeof repaymentCapacity2024Blueprint>>

export const repaymentCapacityBlueprint = (state: ScenarioState) => {
  switch (state.blueprintVersion) {
    default:
      return { version: state.blueprintVersion, ...repaymentCapacity2024Blueprint(state) }
  }
}
