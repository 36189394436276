import { useMemo } from 'react'

import { useLocalStorageValue } from '@react-hookz/web/useLocalStorageValue/index.js'
import { type ScenarioNavigationId } from '@via/components'

interface ScenarioPageLocalState {
  readonly collapsed?: Record<string, boolean>
  readonly currentWeight?: string
  readonly currentBlueprintState?: string
  readonly tabsState?: Record<string, string>
}

export const useScenarioMonetaryLocalState = (
  budgetId: string,
  scenarioId: string,
  navigationId: ScenarioNavigationId
) => {
  const { value, set } = useLocalStorageValue<ScenarioPageLocalState>(
    `via-scenario-${budgetId}-${scenarioId}-${navigationId}`,
    { defaultValue: {} }
  )

  return useMemo(
    () => ({
      collapsed: value?.collapsed,
      currentWeight: value?.currentWeight,
      currentBlueprintState: value?.currentBlueprintState,
      ...(value?.tabsState && { tabsState: value?.tabsState }),

      onCollapse: (collapsed: Record<string, boolean>) => set({ ...value, collapsed }),
      onWeightChange: (currentWeight?: string) => set({ ...value, currentWeight }),
      onBlueprintStateChange: (currentBlueprintState: string) => set({ ...value, currentBlueprintState }),
      onTabChange: (section: string, selectedTab: string) =>
        set({ ...value, tabsState: { ...value?.tabsState, [section]: selectedTab } }),
    }),
    [value, set]
  )
}
