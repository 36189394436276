import { useCallback } from 'react'

import { captureMessage } from '@sentry/react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NoopCallback = (...args: any[]) => Promise<void> | void

export const useNoopCallback = (name: string) =>
  useCallback<NoopCallback>(() => {
    if (import.meta.env.DEV) {
      throw new Error(`Noop ${name} callback called`)
    }
    captureMessage(`Noop ${name} callback called`)
  }, [name])
